import { Pipe, PipeTransform } from "@angular/core";
import { AlbiOption } from "albi-ui/dist/albi-ui-library";

@Pipe({
    name: 'castObjectToAlbiOptionPipe'
})
export class CastObjectToAlbiOptionPipe implements PipeTransform {

    constructor(
    ) { }

    transform(value: object[], ...args: [{ idKey: string, labelKey: string }]): AlbiOption[] {

        return value.map(elm => ({
            key: elm[args[0].idKey as keyof object],
            label: elm[args[0].labelKey as keyof object]
        }));
    }
}