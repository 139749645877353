import { ICompanyAccess } from "-albicchiere-types/lib/base/company";
import { IUserCompanyAccess } from "-albicchiere-types/lib/base/user";
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlbiDropdownSizeEnum, AlbiOption } from "albi-ui/dist/albi-ui-library";
import { BehaviorSubject, tap } from "rxjs";
import { BackendService } from "src/services/backend.service";
import { HeaderMessageService } from "src/services/header-message.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";
import { AlbiUser } from "src/utils/sharedValues";

type UserAccessesFormArray = FormArray<FormGroup<{
    accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
    location: FormControl<AlbiOption>;
    role: FormControl<AlbiOption>;
}>>;

@Component({
    selector: 'user-role-form',
    templateUrl: 'user-role-form.component.html',
    styleUrls: ['user-role-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleFormComponent implements OnInit, AfterViewInit, OnChanges {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    @Input() user: AlbiUser & { accesses?: (IUserCompanyAccess & { roleName?: string, addressName?: string })[] };
    @Input({ required: true }) formStatus: 'assignation' | 'edit';
    @Input({ required: true }) companyLocations: AlbiOption[];
    @Input({ required: true }) roles: AlbiOption[];
    @Input({ required: true }) companyId: string;

    @Output() saveDone = new EventEmitter();
    @Output() handleSaveAction = new EventEmitter<{
        accesses: {
            accessObject?: IUserCompanyAccess | ICompanyAccess,
            role: AlbiOption,
            location: AlbiOption
        }[],
        email?: string
    }>();

    //@Input() existingUserAccesses: IUserCompanyAccess[];


    public roleScopeValues: {
        key: string;
        name?: string;
        description?: string;
    }[];

    AlbiDropdownSizeEnum = AlbiDropdownSizeEnum;

    roleScopeControl: FormControl<'company' | 'location'>;

    emailInputControl: FormControl<string>;

    accessesFormArray: UserAccessesFormArray;

    companyRoleDescription: string;
    locationRoleDescription: string;

    locationOptions$ = new BehaviorSubject<AlbiOption[]>([]);

    public readonly userRoleformDictionary = COMPONENTS_DICTIONARY.userRoleForm;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _elementRef: ElementRef,
        private _backendService: BackendService,
        private _headerMessageService: HeaderMessageService,
        private _store: Store,
        private _translateService: TranslateService,
    ) {
        this.roleScopeControl = new FormControl<'company' | 'location'>('location');
        this.emailInputControl = new FormControl<string>(undefined, Validators.required);

        this.locationRoleDescription = `Seleziona la location e il relativo ruolo che intendi assegnare all’utente. Fai salva per continuare.`;
        this.companyRoleDescription = `Seleziona il ruolo e fai salva per continuare.`;
    }
    ngOnChanges(changes: SimpleChanges): void {
        //console.log(changes['user'].currentValue)     
        if (changes?.['user']?.currentValue.accesses?.[0].location === undefined) {
            this.roleScopeControl.setValue('company');
        } else {
            this.roleScopeControl.setValue('location');
        }
    }
    ngOnInit(): void {

        this.roleScopeValues = [{
            key: 'location',
            name: this._translateService.instant(this.userRoleformDictionary.access.locationOption),
        }, {
            key: 'company',
            name: this._translateService.instant(this.userRoleformDictionary.access.companyOption),
        }];
        this.initAccessFormArray();
        if (this.user?.accesses?.[0].location === undefined) {
            this.roleScopeControl.setValue('company');
        }
        this.calculateAccessFormArray(this.roleScopeControl.value);
        this.roleScopeControl.valueChanges.pipe(
            tap(value => {
                this.calculateAccessFormArray(value);
                //TODO: fix this -> in some case ended up to be null
                if (!value) {
                    this.roleScopeControl.setValue('location');
                }
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        this.accessesFormArray.valueChanges.pipe(
            tap(accesses => {
                this.locationOptions$.next(
                    this.companyLocations.filter(loc => !accesses.map(acc => acc.location?.key).includes(loc.key))
                )
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

    }
    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('user-role-form')
    }

    removeLocationToRoleForm(index: number) {
        this.accessesFormArray.removeAt(index);
    }
    addLocationToRoleForm() {
        this.accessesFormArray.push(this.createRoleAndLocationGroup());
    }

    saveAction() {
        console.log(this.accessesFormArray.value)
        Object.values(this.accessesFormArray.controls).forEach(control => {
            Object.keys(control.controls).forEach(contrKey => {
                const subContr = control.controls[contrKey as keyof typeof control.controls];
                //for company scope do not check location input
                if (this.roleScopeControl.value !== 'company' || contrKey !== 'location') {
                    subContr.markAllAsTouched();
                    subContr.updateValueAndValidity();
                }
            })
            control.markAllAsTouched();
            control.updateValueAndValidity();
        });
        this.accessesFormArray.updateValueAndValidity();
        if (this.user) {
            if (this.accessesFormArray.valid) {
                this.handleSaveAction.emit({
                    accesses: this.accessesFormArray.getRawValue()
                });
            }

        } else {
            this.emailInputControl.markAsTouched();
            this.emailInputControl.updateValueAndValidity();
            if (this.accessesFormArray.valid && this.emailInputControl.valid) {
                this.handleSaveAction.emit({
                    accesses: this.accessesFormArray.getRawValue(),
                    email: this.emailInputControl.value
                });
            }


        }
    }


    private createRoleAndLocationGroup(location?: AlbiOption, role?: AlbiOption, accessObject?: IUserCompanyAccess | ICompanyAccess): FormGroup<{
        accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
        location: FormControl<AlbiOption>;
        role: FormControl<AlbiOption>;
    }> {
        if (accessObject) {
            return new FormGroup<{
                accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
                location: FormControl<AlbiOption>;
                role: FormControl<AlbiOption>;
            }>({
                accessObject: new FormControl<IUserCompanyAccess | ICompanyAccess>(accessObject),
                location: new FormControl(location || undefined, Validators.required),
                role: new FormControl(role || undefined, Validators.required)
            })
        }
        return new FormGroup({
            location: new FormControl(location || undefined, Validators.required),
            role: new FormControl(role || undefined, Validators.required)
        });
    }

    private clearAccessFormArray() {
        if (!this.accessesFormArray) {
            this.initAccessFormArray();
        }
        while (this.accessesFormArray.length !== 0) {
            this.accessesFormArray.removeAt(0);
        }
    }

    private initAccessFormArray() {
        this.accessesFormArray = new FormArray([]);
    }

    private calculateAccessFormArray(roleScope: 'location' | 'company') {
        this.clearAccessFormArray();
        switch (roleScope) {
            case "location":
                const userLocationAddresses = this.user?.accesses?.filter(acc => Boolean(acc.location)) || [];
                if (userLocationAddresses?.length > 0) {
                    //insert user location accesses
                    userLocationAddresses.forEach(access => {
                        this.accessesFormArray.push(this.createRoleAndLocationGroup(
                            { key: access.location, label: access.addressName },
                            { key: access.role, label: access.roleName },
                            access
                        ))
                    })
                } else {
                    //insert empty location access
                    this.accessesFormArray.push(this.createRoleAndLocationGroup())

                }
                break;
            case "company":
                const userCompanyAccesses = this.user?.accesses?.filter(acc => !Boolean(acc.location)) || [];
                if (userCompanyAccesses?.length > 0) {
                    //insert user company access
                    userCompanyAccesses.forEach(access => {
                        this.accessesFormArray.push(this.createRoleAndLocationGroup(
                            { key: undefined, label: 'Company' },
                            { key: access.role, label: access.roleName },
                            access
                        ))
                    })
                } else {
                    //insert empty company access
                    this.accessesFormArray.push(this.createRoleAndLocationGroup(
                        { key: undefined, label: 'Company' },
                        undefined,
                        undefined
                    ))

                }
                break;
        }
        if (roleScope === 'company') {
            this.accessesFormArray.controls.at(0).controls.location.disable();
        }

    }

}