<ng-container *ngIf="{
countrySuggestions:countrySuggestions$ | async,
} as vm">
    <div class="impressum-box-container">
        <div class="head-wrapper">
            <h4>{{impressumBoxDictionary.description | translate}}</h4>
            <albi-icon name="arrow.close" (onClick)="onCloseClick($event)"></albi-icon>
        </div>
        <albi-dropdown class="primary-bg-color" *ngIf="dropdownOptions" [options]="dropdownOptions"
            [formControl]="formGroup.controls.dropdownControl"></albi-dropdown>
        <ng-container [ngSwitch]="inputTextType">
            <albi-input *ngSwitchCase="'input'" [control]="formGroup.controls.textControl" [fullWidth]="true"
                [placeholder]="impressumBoxDictionary.inputPlaceholder | translate"></albi-input>
            <albi-textarea *ngSwitchCase="'textArea'" [control]="formGroup.controls.textControl"></albi-textarea>
        </ng-container>
        <div class="country-wrapper">
            <h4>{{impressumBoxDictionary.nation | translate}}</h4>
            <albi-chips [forceSelection]="true" [dropdownButton]="true"
                (completeMethod)="completeCountrySuggestions($event)" [suggestions]="vm.countrySuggestions"
                (onDropdownClick)="completeCountrySuggestions(vm.countrySuggestions?.length?'': $event,vm.countrySuggestions?.length?false: true)"
                [formControl]="formGroup.controls.countryControl"></albi-chips>
        </div>
    </div>
</ng-container>