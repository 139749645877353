<ng-container *ngIf="{
    companyLogoSrc:companyLogoSrc$ | async,
} as vm">
    <div class="form-group">
        <div class="form-input-label col-span-2" *ngIf="formTitle || formDescription">
            <div *ngIf="formTitle" class="text-lg font-bold">{{formTitle}}</div>
            <div *ngIf="formDescription" class="text-sm font-regular">{{formDescription}}</div>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.typeInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.typeInput.description | translate}}</div>
            </div>
            <albi-radio-button [isFluid]="true" radioGroup="companyType" [values]="[
                {
                    key:'winery',
                    name:companyFormDictionary.typeInput.wineryBox.title | translate,
                    description:companyFormDictionary.typeInput.wineryBox.description | translate
                },
                {
                    key:'professional',
                    name:companyFormDictionary.typeInput.profesionalBox.title | translate,
                    description:companyFormDictionary.typeInput.profesionalBox.description | translate
                },
            ]" [formControl]="companyCreateForm.controls.type"></albi-radio-button>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="text-sm font-bold">{{companyFormDictionary.imageInput.title | translate}}</div>
            <div class="d-flex align-center gap-3">
                <img [src]="vm.companyLogoSrc || companyImage" onerror="this.src='assets/company-avatar.png'"
                    height="80px" class="avatar-image medium contain">
                <div class="text-sm font-regular">{{companyFormDictionary.imageInput.description | translate}}</div>
                <albi-button buttonVariant="textOnly" iconName="arrow.plus"
                    (onClick)="loadCompanyImage()"></albi-button>
            </div>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.nameInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.nameInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.name"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.emailInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.emailInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.email"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.fullNameInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.fullNameInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.fullName"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.vatNumberInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.vatNumberInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.vatNumber"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.websiteInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.websiteInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.website"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{companyFormDictionary.phoneInput.title | translate}}</div>
                <div class="text-sm font-regular">{{companyFormDictionary.phoneInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyCreateForm.controls.phone"></albi-input>
        </div>
    </div>

</ng-container>