import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AlbiUiLibraryModule } from "albi-ui/dist/albi-ui-library";
import { EXPORT_DECLARATIONS } from "./app.routing";

@NgModule({
    declarations: [
        ...EXPORT_DECLARATIONS,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AlbiUiLibraryModule,
    ],
    exports: [
        ...EXPORT_DECLARATIONS,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AlbiUiLibraryModule,
    ]
})
export class SharedModule { }