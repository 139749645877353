export const COMPANY_CONNECT_TRANSLATIONS_EN = {
    companyConnect: {
        mainPage: {
            helloMessage: 'Hello {{name}}',
            findCompanyTitle: 'Find your company',
            findCompanyDescription: `Welcome to the professional section of Albicchiere, here you will find all the services 
            designed for wineries, restaurants, hotels and professional operators in the world of wine. Connect to a company 
            to start working in our platform. Search for your company, select it and press continue to 
            start the association flow.`,
            findCompanyButton: 'Continue',
            createNewCompanyButton: 'Create a new company'
        },
        companyCreate: {
            welcomeMessage: `Welcome {{name}}`,
            companyForm: {
                title: `Company Data`,
                description: `Fill in the information needed to properly configure your company.`,
            },
            addressForm: {
                title: `Address`,
                description: `Enter the main address of your company`,
            },
            saveButton: `Save`,
        },
        companyPendingVerification: {
            pageTitle: `Company pairing`,
            pending: {
                pageHeaderMessage: {
                    mainText: `Request sent, you are waiting for acceptance.
                                If you need help or want to claim ownership of the company,
                                contact Albicchiere support.`,
                    linkText: `Open a Ticket`,
                },
                updateText: `Last update {{updatedAt}}`,
                resendButton: `Resend the request`,
                cancelButton: `Cancel request`,
            },
            email: {
                text: `Open your email "{{email}}" to confirm the request`,
            },
            document: {
                description: `Company security is important to us. To prevent unauthorized people
                                            from
                                            accessing your company profile, we need some
                                            documents
                                            for verification.`,
                updateText: `Last update {{updatedAt}}`,
                uploadButton: `Upload`,
                documents: `Documents`,
                documentName: `Name: `,
                sendButton: `Send`,
                loadedDocuments: `Uploaded Documents`,
                status: {
                    pending: `Pending review`,
                    rejected: `Rejected`,
                    approved: `Approved`,
                },
                findOutButton: `Find out which documents you can upload`,
                documentPanel: {
                    title: `Documents`,
                    description: `You can upload one of the following documents to prove that you are the owner of the
                            selected
                            company:`,
                    bankStatement: `Bank statement`,
                    registrationCert: `Registration certificate`,
                    doc: `Doc`,
                }

            }
        },
        emailVerificationPage: {
            loading: {
                title: `Email verification in progress`,
            },
            success: {
                title: `Email verification successful`,
                desciption: `Now you can manage your company in the dedicated Albicchiere Dashboard`,
                home: `Home`,
            },
            error: {
                title: `Email verification failed`,
                desciption: `An error occurred during the verification process {{error}}`,
                button: `Send a new verification email`,
            }
        },
        companyInviteVerification: {
            title: `{{name}} invited you`,
            description: `You were invited by {{name}}. Press the button to
                        accept the invitation and get started
                        to work for this company.`,
            acceptButton: `Accept`,
        }
    }
}