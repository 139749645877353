import { createAction, props } from '@ngrx/store';
import { AlbiOption } from 'albi-ui/dist/albi-ui-library';
import { AlbiUserCompanyAccess, COMPANY_USER_FEATURE, ResolvedCompanyAccesses } from './company-user.state';

export const COMPANY_USER_PAGE_ACTIONS = {
    initCompanyUserState: createAction(`[${COMPANY_USER_FEATURE}] Init Company User State`),


    updateCompanyUserState: createAction(`[${COMPANY_USER_FEATURE}] Update Company User State`),
    setCompanyUserLocation: createAction(`[${COMPANY_USER_FEATURE}] Set Company User Location`, props<{ location: AlbiOption }>()),
};

export const COMPANY_USER_API_ACTIONS = {
    initCompanyUserStateSuccess: createAction(
        `[${COMPANY_USER_FEATURE}] Init Company User State Success`,
        props<{
            companyAdmins: AlbiUserCompanyAccess[],
            pendingUsers: AlbiUserCompanyAccess[],
            activeUsers: AlbiUserCompanyAccess[],
            companyAccesses: ResolvedCompanyAccesses[]
        }>()
    ),
    initCompanyUserStateFailure: createAction(
        `[${COMPANY_USER_FEATURE}] Init Company User State Failure`,
        props<{ error: string }>()

    ),


    updateCompanyUserStateSuccess: createAction(
        `[${COMPANY_USER_FEATURE}] Update Company User State Success`,
        props<{
            companyAdmins: AlbiUserCompanyAccess[],
            pendingUsers: AlbiUserCompanyAccess[],
            activeUsers: AlbiUserCompanyAccess[],
            companyAccesses: ResolvedCompanyAccesses[]
        }>()
    ),
    updateCompanyUserStateFailure: createAction(
        `[${COMPANY_USER_FEATURE}] Update Company User State Failure`,
        props<{ error: string }>()

    ),
};

