export const PROFILE_TRANSLATIONS_IT = {
    profile: {
        topDivider: {
            title: `Profilo`,
            handleEmailButton: `Gestisci email`,
            handlePasswordButton: `Gestisci password`,
            logoutButton: `Logout`,
            changeEmailPanel: {
                title: `Cambia Email`,
                currEmailInput: `Email corrente`,
                newEmailInput: `Nuova email`,
            },
            changePasswordPanel: {
                title: `Cambia Password`,
                description: `La password deve essere almeno di 8 caratteri, inoltre deve contenere almeno un numero e un
            carattere speciale`,
                currPasswordInput: `Password corrente`,
                newPasswordInput: `Nuova password`,
                confirmPasswordInput: `Conferma nuova password`,
            }
        },
        info: {
            title: `Dati Personali`,
            nameInput: {
                title: `Nome`,
                description: `Inserisci il tuo nome`,
            },
            lastnameInput: {
                title: `Cognome`,
                description: `Inserisci il tuo cognome`,
            },
            birthdateInput: {
                title: `Data di nascita`,
                description: `Inserisci la tua data di nascita`,
            },
            nationalityInput: {
                title: `Nazionalità`,
                description: `Seleziona la tua nazionalità`,
            },
            genderInput: {
                title: `Genere`,
                description: `Seleziona il tuo genere`,
            },
        },
        settings: {
            title: `Settings`,
            languageInput: {
                title: `Lingua`,
                description: `Seleziona la lingua`,
            },
            measureSystem: {
                title: `Unità di misura`,
                description: `Seleziona l'unità di misura`,
                options: {
                    metricSystem: `Sistema Metrico`,
                    imperialSystem: `Sistema Imperiale`,
                }
            },
            dateFormat: {
                title: `Formato delle date`,
                description: `Seleziona il formato delle date`,
            },
            hourFormat: {
                title: `Formato delle ore`,
                description: `Seleziona il formato delle ore`,
            }
        },
        privacy: {
            title: `Privacy`,
            deleteAccountButton: `Cancella account`,
            consentList: {
                title: `Lista dei consensi forniti`,
                termsAndConditions: `Dichiaro di aver letto e accettato i termini e le condizioni del servizio.`,
                dataProcessing: `Autorizzo il trattamento dei dati per ricevere promozioni e attività di marketing
                                (facoltativo).`,
                accountNotificationTitle: `Notifiche sul tuo account`,
                accountNotificationDescription: `Ricevi notifiche email riguardanti il tuo account (avvisi, scadenze,
                                promozioni, reminder)`,
            },
            rigthsSection: {
                title: `I tuoi diritti`,
                description: `Per richiedere l'accesso ai tuoi dati e per esercitare i tuoi diritti relativi alla tua identità
                    digitale,
                    puoi inviarci una richiesta all'indirizzo "info@albicchiere.com" con oggetto 'privacy'.
                    Risponderemo
                    entro un
                    tempo valido, rispettando i tuoi diritti.`,
            },
            dangerSection: {
                title: `Danger zone`,
                description: `Puoi eliminare il tuo account, il che significa perdere tutti i dati e qualsiasi progresso salvato.
                    La
                    procedura non è retroattiva e non potrai più gestire il dispositivo se ne possiedi uno (è necessario
                    un
                    account per la configurazione e la gestione).`,
            },
            policyConsentPanel: {
                title: `Policy consent`,
                description: `Il consenso ai <a href="https://albicchiere.com/terms-of-service/" target="_blank">termini di servizio</a> e
            all'<a href="https://albicchiere.com/privacy-policy/" target="_blank">informativa sulla privacy</a>
            è necessario per utilizzare il sistema albicchiere. Se desideri revocare il
            consenso, allora procedi a richiedere la cancellazione del tuo account.`,
            },
        },
        tabs: {
            home: `Home`,
            personalData: `Dati personali`,
            company: `Company`,
            settings: `Impostazioni`,
            privacy: `Privacy`,
        }
    }
}