export const SHARED_TRANSLATIONS_IT = {
    shared: {
        button: {
            add: `Aggiungi`,
            next: `Indietro`,
            prev: `Continua`,
            save: `Salva`,
            apply: `Applica`,
            change: `Cambia`,
            download: `Scarica`,
            edit: `Modifica`,
            go: `Vai`,
            delete: `Rimuovi`,
            send: `Invia`
        },
        wine: {
            color: {
                red: `Rosso`,
                white: `Bianco`,
                rose: `Rosè`,
            },
            types: {
                sparkling: `Frizzante`,
                fortified: `Fortificato`,
                sweet: `Dolce`,
            }
        },
        company: {
            type: {
                winery: `Cantina`,
                professional: `Professional`,
            }
        },
        user: {
            gender: {
                male: `Maschio`,
                female: `Femmina`,
                other: `Altro`,
            }
        },
        dashboardTabs: {
            home: `Home`,
            elabel: `ELabel`,
            wines: `Vini`,
            dispenser: 'Dispenser',

            shop: `Shop`,
            company: `Company`,
            profile: `Profilo`,
        },
        popupMessages: {
            error: {
                generic: `ERRORE: {{error}}`,
                emailNotFound: `ERRORE: nessun utente trovato con questa email`,
                codeMismatch: `ERRORE: il codice inserito non è corretto`,
                createVintage: `ERRORE: durante la creazione del vino qualcosa è andato storto`,
                notCantinaCompany: `ERRORE: per poter proseguire devi operare per conto di una cantina`,
                wineryWronglySelected: `ERRORE: la cantina non è correttamente selezionata`,
            },
            success: {
                companyCreated: `L'azienda è stata creata con successo`,
                requestSent: `La richiesta è stata inviata correttamente`,
                addressCreated: `La location è stata creata corretamente`,
                dataUpdated: `I dati sono stati aggiornati correttamente`,
                accessDisabled: `L'accesso dell'utente {{email}} è stato disabilitato`,
                accessDenied: `La richiesta dell'utente {{email}} è stata respinta`,
                companyAccessDisabled: `L'accesso della company {{name}} è stato disabilitato`,
                companyAccessDenied: `La richiesta della company {{name}} è stata respinta`,
                userInvited: `L'utente {{email}} è stato invitato ad accedere alla tua azienda`,
                userAccessAccepted: `Hai approvato l'accesso per l'utente {{email}}`,
                companyAccessAccepted: `Hai dato accesso alla company {{name}}`,
                vintageCreated: `Il vino è stato creato correttamente`,
                recycleModelAdded: `Il modello di riciclo è stato aggiunto correttamente`,
                passwordUpdated: `La password è stata aggiornata con successo`,
                requestDeleted: `La richiesta è stata cancellata correttamente`,
            },
            warning: {
                fileFormatNotValid: `ATTENZIONE: il formato del file non è valido, carica file di tipo {{files}}`,
                compileMandatoryFields: `ATTENZIONE: per procedere compila tutti i campi obbligatori`,
                passwordMismatch: `ATTENZIONE: le password inserite non coincidono`,
                imageSize: `ATTENZIONE: la dimensione delle immagini non rispetta lo standard richiesto, ma`,
                formNotValid: `ATTENZIONE: compila correttamente tutti i campi obbligatori`,
                verifyCompanyToProceed: `ATTENZIONE: Carica i documenti da verificare per poter procedere`,
            }
        },
        mainSearchbarSuggestions: {
            companySection: `Sezione Company`,
            companyUserSection: `Gli utenti che hanno accesso alla tua company`,
            wineSection: `Sezione Wines`,
            elabelSection: `Sezione Elabels`,
            profileSection: `Sezione Profilo`,
            profileCompanySection: `Le company alle quali sei connesso`,
        }
    }
}