export const COMPANY_CONNECT_TRANSLATIONS_IT = {
    companyConnect: {
        mainPage: {
            helloMessage: 'Ciao {{name}}',
            findCompanyTitle: 'Trova la tua company',
            findCompanyDescription: `Benvenuto nella sezione professional di Albicchiere, qui troverai tutti i servizi 
            pensati per cantine, ristoranti, hotel e operatori professional nel mondo del vino. Collegati a una azienda 
            per iniziare a lavorare nella nostra piattaforma. Cerca la tua company, selezionala e premi continua per 
            iniziare il flusso di associazione.`,
            findCompanyButton: 'Continua',
            createNewCompanyButton: 'Crea una nuova azienda'
        },
        companyCreate: {
            welcomeMessage: `Benvenuto {{name}}`,
            companyForm: {
                title: `Dati azienda`,
                description: `Compila le informazioni necessarie per configurare correttamente la tua azienda.`,
            },
            addressForm: {
                title: `Indirizzo`,
                description: `Inserisci l'indirizzo principale della tua azienda`,
            },
            saveButton: `Salva`,
        },
        companyPendingVerification: {
            pageTitle: `Company pairing`,
            pending: {
                pageHeaderMessage: {
                    mainText: `Richiesta inviata, sei in attesa di accettazione.
                                 Se hai bisogno di aiuto o vuoi rivendicare la titolarità dell’azienda,
                                  contatta l’assistenza Albicchiere.`,
                    linkText: `Apri un Ticket`,
                },
                updateText: `Ultimo aggiornamento {{updatedAt}}`,
                resendButton: `Sollecita approvazione`,
                cancelButton: `Annulla richiesta`,
            },
            email: {
                text: `Apri la tua email "{{email}}" per confermare la richiesta`,
            },
            document: {
                description: `La sicurezza delle company ci sta a cuore. Per evitare che persone non
                                            autorizzate
                                            possano accedere al profilo della tua company, abbiamo bisogno di alcuni
                                            documenti
                                            di verifica.`,
                updateText: `Ultimo aggiornamento {{updatedAt}}`,
                uploadButton: `Carica`,
                documents: `Documenti`,
                documentName: `Nome: `,
                sendButton: `Invia`,
                loadedDocuments: `Documenti caricati`,
                status: {
                    pending: `In attesa di revisione`,
                    rejected: `Rifiutato`,
                    approved: `Approvato`,
                },
                findOutButton: `Scopri quali documenti potrai caricare`,
                documentPanel: {
                    title: `Documenti`,
                    description: `Puoi caricare uno dei seguenti documenti per dimostrare di essere il proprietario della
                            company
                            scelta:`,
                    bankStatement: `Estratto conto`,
                    registrationCert: `Visura`,
                    doc: `Doc`,
                }

            }
        },
        emailVerificationPage: {
            loading: {
                title: `Verifica via email in corso`,
            },
            success: {
                title: `Verifica email Effettuata`,
                desciption: `Ora puoi gestire la tua Azienda nella Dashboard dedicata di Albicchiere`,
                home: `Home`,
            },
            error: {
                title: `Verifica email Fallita`,
                desciption: `Durante la fase di verifica è stato riscontrato un errore {{error}}`,
                button: `Invia una nuova email di verifica`,
            }
        },
        companyInviteVerification: {
            title: `{{name}} ti ha invitato`,
            description: `Sei stato invitato da {{name}}. Premi il pulsante per
                        accettare l’invito e iniziare
                        a lavorare per questa azienda.`,
            acceptButton: `Accetta`,
        }
    }
}