<ng-container *ngIf="{
    glassess: glassess$ | async,
} as vm">
    <div class="d-flex flex-wrap">
        <ng-container *ngFor="let glass of vm.glassess">
            <ng-container [ngSwitch]="glass.glassType">
                <div *ngSwitchCase="'full'">
                    <ng-container [ngSwitch]="wineColor">
                        <albi-icon *ngSwitchCase="'red'" name="wine.glassFullRed"></albi-icon>
                        <albi-icon *ngSwitchCase="'white'" name="wine.glassFullWhite"></albi-icon>
                        <albi-icon *ngSwitchCase="'rose'" name="wine.glassFullRose"></albi-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'half'">
                    <ng-container [ngSwitch]="wineColor">
                        <albi-icon *ngSwitchCase="'red'" name="wine.glassHalfRed"></albi-icon>
                        <albi-icon *ngSwitchCase="'white'" name="wine.glassHalfWhite"></albi-icon>
                        <albi-icon *ngSwitchCase="'rose'" name="wine.glassHalfRose"></albi-icon>
                    </ng-container>
                </div>
                <div *ngSwitchCase="'empty'">
                    <albi-icon name="wine.glassEmpty"></albi-icon>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>