<div class="d-flex align-center">
    <div *ngIf="leftPointColor" class="left-point-container">
        <ng-container [ngSwitch]="leftPointColor">
            <span *ngSwitchCase="'orange'" class="left-point orange"></span>
            <span *ngSwitchCase="'gold'" class="left-point gold"></span>
            <span *ngSwitchCase="'rose'" class="left-point rose"></span>
            <span *ngSwitchCase="'purple'" class="left-point purple"></span>
            <span *ngSwitchCase="'info'" class="left-point info"></span>
            <span *ngSwitchCase="'eco'" class="left-point eco"></span>
        </ng-container>
    </div>
    {{value.name}}
</div>
<div class="d-flex gap-1 align-center text-xs font-medium">
    <div class="mobile-d-flex">
        <div *ngFor="let bool of value.boolean; index as i" class="d-flex align-center">
            <span>
                {{booleanText[i]}}
            </span>

            <albi-switch size="small" [value]="bool?.booleanValue || false"
                (valueChange)="onValueChange($event,i)"></albi-switch>
        </div>
    </div>
    <albi-icon name="arrow.close" (onClick)="handleCloseClick()"></albi-icon>
</div>