import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, map } from "rxjs";
import { USER_ACCES_SELECTORS } from "src/userAccessStore/userAccessStore.selectors";

@Injectable()
export class CompanyGuard {

    constructor(private _store: Store, private _router: Router, private _ar: ActivatedRoute) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | void> {
        return combineLatest([
            this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses),
        ]).pipe(
            map(([selectedCompanyAccess]) => {
                selectedCompanyAccess ? true : this._router.navigate(['company-connect']);
            })
        );
    }
}