<ng-container *ngIf="{
    user:user$ | async,
    companyAccesses: companyAccesses$ | async,
    canChangeCompany: canChangeCompany$ | async,
    selectedCompanyAccess: selectedCompanyAccess$ | async,
    showEnterpriseCompanyAccessPanel:showEnterpriseCompanyAccessPanel$ | async,
    mainBarSuggestions: mainBarSuggestions$ | async,

} as vm">
    <app-page-layout [verticalTabs]="exampleTabs">
        <router-outlet></router-outlet>
    </app-page-layout>
</ng-container>