import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'resolveCountryCode'
})
export class ResolveCountryCodePipe implements PipeTransform {

    constructor(
        private _translate: TranslateService,
    ) { }

    transform(value: string, ...args: any[]) {
        if (value.length === 2) {
            const regionNamesInUserLocale = new Intl.DisplayNames([this._translate.currentLang], { type: 'region' });
            return regionNamesInUserLocale.of(value.toUpperCase());
        }
        return value;
    }
}