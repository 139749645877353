import { createSelector } from '@ngrx/store';
import { UserAccessState } from './userAccessStore.state';

const selectUserAcces = (state: any): UserAccessState => state.userAcces;

export const USER_ACCES_SELECTORS = {
    selectError: createSelector(
        selectUserAcces,
        (state) => state.error
    ),

    selectIsLoading: createSelector(
        selectUserAcces,
        (state) => state.isLoading
    ),

    selectUserAccesses: createSelector(
        selectUserAcces,
        (state) => state.userAccesses
    ),

    selectSelectedUserAccesses: createSelector(
        selectUserAcces,
        (state) => state.selectedUserAccess
    ),
    selectCanChangeUserAcces: createSelector(
        selectUserAcces,
        (state) => state.canChangeUserAccess
    ),
    selectEnterpriseAccesses: createSelector(
        selectUserAcces,
        (state) => state.enterpriseAccesses
    ),
    selectSelectedEnterpriseAccess: createSelector(
        selectUserAcces,
        (state) => state.selectedEnterpriseAccess
    ),

    selectShowEnterpriseAccessPanel: createSelector(
        selectUserAcces,
        (state) => state.showEnterpriseAccessPanel
    ),
    selectInitStoreDone: createSelector(
        selectUserAcces,
        (state) => state.initStoreDone
    ),
}

