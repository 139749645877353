<ng-container *ngIf="{
    locationOptions:locationOptions$ | async,
} as vm">
    <div class="user-hero" *ngIf="user">
        <span>
            <img class="avatar-image medium" [src]="user.avatar" onerror="this.src='assets/user-avatar.png'">
        </span>
        <div class="text-info">
            <div class="text-sm font-bold">{{user.name}} {{user.lastName}}
            </div>
            <div class="lowercase text-sm font-regular">{{user.email}}</div>
        </div>
    </div>
    <div>
        <div class="text-sm font-bold">{{userRoleformDictionary.access.title | translate}}</div>
        <div class="text-sm font-regular">{{userRoleformDictionary.access.description | translate}}</div>
    </div>
    <albi-radio-button [values]="roleScopeValues" [isFluid]="true" radioGroup="roleScope"
        [formControl]="roleScopeControl"></albi-radio-button>
    <div>
        <div class="text-sm font-bold">{{userRoleformDictionary.role.title | translate}}</div>
        <div class="text-sm font-regular">
            {{roleScopeControl.value==='location'?
            (userRoleformDictionary.role.locationRoleDescription| translate):
            (userRoleformDictionary.role.companyRoleDescription | translate)}}

        </div>
    </div>
    <div *ngFor="let controlGroup of accessesFormArray.controls; index as i "
        class="control-group-wrapper mobile-d-flex w-full align-center gap-3">
        <albi-dropdown [size]="AlbiDropdownSizeEnum.FLUID" [options]="vm.locationOptions"
            [formControl]="controlGroup.controls.location">
            <ng-template #itemTemplate let-option>
                {{option.value.label}}
            </ng-template>
        </albi-dropdown>
        <albi-dropdown [size]="AlbiDropdownSizeEnum.FLUID" [options]="roles" [formControl]="controlGroup.controls.role">
            <ng-template #itemTemplate let-option>
                {{option.value.label}}
            </ng-template>
        </albi-dropdown>
        <div>
            <albi-icon class="cliccable" *ngIf="i>0" name="delete" (onClick)="removeLocationToRoleForm(i)"></albi-icon>
            <albi-icon *ngIf="i===0" name="placeholder" class="no-action"></albi-icon>
        </div>
    </div>
    <div class="d-flex j-end" *ngIf="roleScopeControl.value==='location' && vm.locationOptions?.length>0">
        <albi-button buttonVariant="textOnly" iconName="arrow.plus"
            [label]="userRoleformDictionary.role.addLocationButton | translate"
            (onClick)="addLocationToRoleForm()"></albi-button>
    </div>
</ng-container>