import { createSelector } from '@ngrx/store';
import { CompanyUserState } from './company-user.state';

const selectCompanyUser = (state: any): CompanyUserState => state.company;

export const COMPANY_USER_SELECTORS = {
    selectError: createSelector(
        selectCompanyUser,
        (state) => state.error
    ),

    selectCompanyAdmins: createSelector(
        selectCompanyUser,
        (state) => state.companyAdmins
    ),

    selectActiveUsers: createSelector(
        selectCompanyUser,
        (state) => state.activeUsers
    ),

    selectPendingUsers: createSelector(
        selectCompanyUser,
        (state) => state.pendingUsers
    ),
    selectSelectedLocation: createSelector(
        selectCompanyUser,
        (state) => state.selectedLocation
    ),
    selectCompanyAccesses: createSelector(
        selectCompanyUser,
        (state) => state.companyAccesses
    ),
}

