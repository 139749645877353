<div class="color-picker-container">
    <span #colorCodeText contenteditable="true" (input)="onColorCodeChange($event)"></span>

    <div class="color-preview" [style.background]="colorCode" (click)="openColorPicker()">
        <input #colorPicker type="color" (input)="onColorPickerChange($event)" style="visibility: hidden;" />
    </div>


</div>
<!--
    <input type="text" [(ngModel)]="colorCode" (input)="onColorCodeChange()" maxlength="7" placeholder="#000000"
        class="color-input" />
        -->