import { IRole } from "-albicchiere-types/lib/base/role";
import { Pipe, PipeTransform } from "@angular/core";
import { map } from "rxjs";
import { BackendService } from "src/services/backend.service";

@Pipe({
    name: 'resolveRolePipe'
})
export class ResolveRolePipe implements PipeTransform {

    constructor(
        private _bs: BackendService
    ) { }

    transform(value: string, ...args: any[]) {

        return this._bs.get<{ roles: IRole[] }>(`roles`).pipe(
            map(response => response.roles.find(elm => elm._id === value).name)
        )
    }
}