import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'isExpiredPipe'
})
export class IsExpiredPipe implements PipeTransform {

    constructor() { }

    transform(value: string | Date, ...args: []) {
        const now = new Date();
        const expirationDate = typeof value === 'string' ? new Date(value) : value;
        return expirationDate < now;
    }
}