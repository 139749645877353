import { ICompany, ICompanyAccess, ICompanyAddress } from '-albicchiere-types/lib/base/company';
import { IRole } from '-albicchiere-types/lib/base/role';
import { IUserCompanyAccess, UserAccessStatusEnum } from '-albicchiere-types/lib/base/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AlbiOption } from 'albi-ui/dist/albi-ui-library';
import { cloneDeep } from 'lodash';
import { catchError, combineLatest, map, mergeMap, of } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { BackendService } from 'src/services/backend.service';
import { USER_ACCES_SELECTORS } from 'src/userAccessStore/userAccessStore.selectors';
import { COMPANY_USER_API_ACTIONS, COMPANY_USER_PAGE_ACTIONS } from './company-user.action';
import { COMPANY_USER_SELECTORS } from './company-user.selector';
import { AlbiUser, AlbiUserCompanyAccess, ResolvedCompanyAccesses } from './company-user.state';


type CompanyAccessResolveAllResponse = {
    _id?: string;
    company: ICompany;
    location?: ICompanyAddress;
    role: IRole;
    status: UserAccessStatusEnum;
}

@Injectable()
export class CompanyUserEffects {

    constructor(private actions$: Actions, private _authService: AuthService,
        private _router: Router, private _backendService: BackendService, private _store: Store) { }


    initCompanyUserState = createEffect(() =>
        this.actions$.pipe(
            ofType(COMPANY_USER_PAGE_ACTIONS.initCompanyUserState),
            mergeMap(() =>
                this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
                    map(access => {
                        if (!['Admin', 'AdminUnverified'].includes(access.role.name)) {
                            throw new Error('User is not Admin')
                        }
                        return access
                    }),
                    mergeMap((access) => combineLatest([
                        this._backendService.get<{ user_accesses: (IUserCompanyAccess & { user: AlbiUser })[] }>(`companies/${access.company._id}/user_accesses`),
                        this._backendService.get<{ addresses: ICompanyAddress[] }>(`companies/${access.company._id}/addresses`),
                        this._backendService.get<{ roles: IRole[] }>(`roles`),
                        this._backendService.get<{ company_accesses: CompanyAccessResolveAllResponse[] }>(`companies/${access.company._id}/company_accesses?resolveAll=true`),
                        this._store.select(COMPANY_USER_SELECTORS.selectSelectedLocation),
                    ]).pipe(
                        map(([companyAccesses, companyAddresses, roles, givenCompanyAccesses, selectedCompanyAddress]) => COMPANY_USER_API_ACTIONS.initCompanyUserStateSuccess({
                            activeUsers: this.calculateNonAdminUsers(companyAccesses.user_accesses, companyAddresses.addresses, roles.roles, selectedCompanyAddress),
                            companyAdmins: this.calculateCompanyAdmins(companyAccesses.user_accesses, companyAddresses.addresses, roles.roles),
                            pendingUsers: this.calculatePendingUsers(companyAccesses.user_accesses),
                            companyAccesses: this.calculateActiveCompanies(givenCompanyAccesses.company_accesses)
                        })
                        )
                    )),
                    catchError(error => of(COMPANY_USER_API_ACTIONS.initCompanyUserStateFailure({ error: error.message })))
                )
            ),
            catchError(error => of(COMPANY_USER_API_ACTIONS.initCompanyUserStateFailure({ error: error.message })))
        )
    );


    updateCompanyUserState = createEffect(() =>
        this.actions$.pipe(
            ofType(COMPANY_USER_PAGE_ACTIONS.updateCompanyUserState),
            mergeMap(() =>
                this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses).pipe(
                    mergeMap((access) => combineLatest([
                        this._backendService.get<{ user_accesses: (IUserCompanyAccess & { user: AlbiUser })[] }>(`companies/${access.company._id}/user_accesses`),
                        this._backendService.get<{ addresses: ICompanyAddress[] }>(`companies/${access.company._id}/addresses`),
                        this._backendService.get<{ roles: IRole[] }>(`roles`),
                        this._backendService.get<{ company_accesses: CompanyAccessResolveAllResponse[] }>(`companies/${access.company._id}/company_accesses?resolveAll=true`),
                        this._store.select(COMPANY_USER_SELECTORS.selectSelectedLocation),
                    ]).pipe(
                        map(([companyAccesses, companyAddresses, roles, givenCompanyAccesses, selectedCompanyAddress]) =>
                            COMPANY_USER_API_ACTIONS.updateCompanyUserStateSuccess({
                                activeUsers: this.calculateNonAdminUsers(companyAccesses.user_accesses, companyAddresses.addresses, roles.roles, selectedCompanyAddress),
                                companyAdmins: this.calculateCompanyAdmins(companyAccesses.user_accesses, companyAddresses.addresses, roles.roles),
                                pendingUsers: this.calculatePendingUsers(companyAccesses.user_accesses),
                                companyAccesses: this.calculateActiveCompanies(givenCompanyAccesses.company_accesses)
                            }))
                    )),
                    catchError(error => of(COMPANY_USER_API_ACTIONS.updateCompanyUserStateFailure({ error: error.message })))
                )
            ),
            catchError(error => of(COMPANY_USER_API_ACTIONS.updateCompanyUserStateFailure({ error: error.message })))
        )
    );


    private calculateNonAdminUsers(
        companyAccesses: (IUserCompanyAccess & { user: AlbiUser })[],
        companyAddresses: ICompanyAddress[],
        roles: IRole[],
        selectedCompanyAddress?: AlbiOption,
    ): AlbiUserCompanyAccess[] {
        const companyAdminRoles = roles.filter(elm => elm.name === 'Admin' || elm.name === 'AdminUnverified').map(elm => elm._id);
        const adminUsers = this.calculateCompanyActiveUsers(companyAccesses, companyAddresses, roles, selectedCompanyAddress)
            .filter(elm => !elm.accesses.some(access => access.location == undefined && companyAdminRoles.includes(access.role)));
        return adminUsers;
    }

    calculateActiveCompanies(companyAccesses: CompanyAccessResolveAllResponse[]) {
        const groupedCompanyAccesses = companyAccesses.reduce((acc: (ResolvedCompanyAccesses & { locationTag?: string, roleTag?: string })[], curr) => {
            const companyIndex = acc.findIndex(elm => elm.company._id === curr.company._id);
            const accessObject: ICompanyAccess & { status: UserAccessStatusEnum } = {
                company: curr.company._id,
                location: curr.location._id,
                role: curr.location._id,
                status: curr.status,
                _id: curr._id,
            }
            if (companyIndex === -1) {
                acc.push({
                    company: curr.company,
                    accesses: [{
                        accessObject: accessObject,
                        role: curr.role,
                        location: curr.location?._id ? curr.location : undefined,
                    }]
                })
            } else {
                acc[companyIndex].accesses.push({
                    accessObject: accessObject,
                    role: curr.role,
                    location: curr.location?._id ? curr.location : undefined
                })
            }
            return acc
        }, []);
        groupedCompanyAccesses.map(elm => {
            if (elm.accesses.length === 1) {
                elm.locationTag = elm.accesses[0].location?.name || 'company';
                elm.roleTag = elm.accesses[0].role.name;
            } else {
                elm.locationTag = 'locations';
                elm.roleTag = elm.accesses.every(access => access.role._id === elm.accesses[0].role._id) ? elm.accesses[0].role.name : 'multi';
            }
            return elm
        })
        return groupedCompanyAccesses;

    }

    private calculatePendingUsers(companyAccesses: (IUserCompanyAccess & { user: AlbiUser })[]) {
        const pendingUserAccesses = companyAccesses.filter(elm => elm.status === UserAccessStatusEnum.PENDING);
        return pendingUserAccesses.map(elm => {
            const access = cloneDeep(elm);
            delete access.user;
            return {
                ...elm.user,
                accesses: [{
                    ...access
                }]
            }
        });
    }

    private calculateCompanyAdmins(
        companyAccesses: (IUserCompanyAccess & { user: AlbiUser })[],
        companyAddresses: ICompanyAddress[],
        roles: IRole[]): AlbiUserCompanyAccess[] {
        const companyAdminRoles = roles.filter(elm => elm.name === 'Admin' || elm.name === 'AdminUnverified').map(elm => elm._id);
        const adminUsers = this.calculateCompanyActiveUsers(companyAccesses, companyAddresses, roles)
            .filter(elm => elm.accesses.some(acc => companyAdminRoles.includes(acc.role) && acc.location == undefined));
        return adminUsers;
    }


    private calculateCompanyActiveUsers(
        companyAccesses: (IUserCompanyAccess & { user: AlbiUser })[],
        companyAddresses: ICompanyAddress[],
        roles: IRole[],
        selectedCompanyAddress?: AlbiOption,
    ): AlbiUserCompanyAccess[] {
        //if selected company address is null no filter (all true) [1° condition]
        //if selected company address exist but elm has no location (scope on all location) = true [2° condition]
        //if selected company address exist and elm has scope on location check elm location with the selected one [3° condition]
        const filteredCompanyAccesses = companyAccesses
            .filter(elm => elm.status === UserAccessStatusEnum.ACTIVE || elm.role)
            .filter(elm => (!Boolean(selectedCompanyAddress?.key) || !Boolean(elm.location) || elm.location == selectedCompanyAddress?.key));

        const calculatedAccesses = filteredCompanyAccesses.reduce((acc: {
            _id: string,
            name: string,
            lastName: string,
            email: string,
            roleTableTag?: string,
            addressTableTag?: string,
            accesses: (IUserCompanyAccess & { roleName?: string, addressName?: string })[],
        }[], curr) => {
            const userIndex = acc.findIndex(elm => elm._id === curr.user._id);
            const currentAccess: IUserCompanyAccess & { roleName?: string, addressName?: string, user: { _id: string, name: string, lastName: string, email: string } } = cloneDeep(curr);
            delete currentAccess.user;
            currentAccess.addressName = companyAddresses.find(elm => elm._id === currentAccess.location)?.name;
            currentAccess.roleName = roles.find(elm => elm._id === currentAccess.role)?.name;
            if (userIndex === -1) {
                //user not found add user, role and address to array of objects
                acc.push({
                    ...curr.user,
                    accesses: [currentAccess],
                });
            } else {
                //user found

                acc[userIndex].accesses.push(currentAccess);
            }
            return acc;
        }, []);

        calculatedAccesses.map(user => {
            if (user.accesses.length === 1) {
                if (user.accesses[0].location && user.accesses[0].location !== 'all') {
                    user.addressTableTag = user.accesses[0].addressName;
                } else {
                    user.addressTableTag = 'Company'
                }
                user.roleTableTag = user.accesses[0].roleName;
            } else {
                user.addressTableTag = 'Locations';
                if (user.accesses.every(access => access.role === user.accesses[0].role)) {
                    user.roleTableTag = user.accesses[0].roleName;
                } else {
                    user.roleTableTag = 'Multi';
                }

            }
            return user;
        })
        return calculatedAccesses;

    }
}