import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ColorPickerComponent),
        multi: true,
    }]
})
export class ColorPickerComponent implements AfterViewInit, ControlValueAccessor {
    @ViewChild('colorPicker') colorPicker: ElementRef;
    @ViewChild('colorCodeText') colorCodeText: ElementRef<HTMLSpanElement>;
    // colorCode: string = '#000000';

    @Input() colorCode: string = '#ff0050';
    @Output() colorCodeChange = new EventEmitter<string>();

    onTouch: () => void;
    onChange: (value: string) => void;

    constructor() { }

    writeValue(obj: string): void {
        obj ? this.colorCode = obj : null;
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouch = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        //implement if needed
    }

    ngAfterViewInit(): void {
        this.onChange ? this.onChange(this.colorCode) : null;
        this.colorCodeText.nativeElement.innerHTML = this.colorCode;
        this.colorPicker.nativeElement.value = this.colorCode;
    }

    onColorCodeChange(ev: Event) {
        this.colorCode = this.colorCodeText.nativeElement.innerText.includes('#') ? this.colorCodeText.nativeElement.innerText : '#' + this.colorCodeText.nativeElement.innerText;
        if (/^#([0-9A-Fa-f]){6}$/i.test(this.colorCode)) {
            console.log('overwrite color')
            // Valid hex color code
            this.colorPicker.nativeElement.value = this.colorCode;
            this.colorCodeChange.emit(this.colorCode);
            this.onChange ? this.onChange(this.colorCode) : null;
            (this.colorPicker.nativeElement as HTMLInputElement).value = this.colorCode;
        }
    }

    openColorPicker() {
        this.colorPicker.nativeElement.click();
    }

    onColorPickerChange(event: any) {
        this.colorCode = event.target.value;
        this.colorCodeText.nativeElement.innerText = this.colorCode;
        this.colorCodeChange.emit(this.colorCode);
        this.onChange ? this.onChange(this.colorCode) : null;
    }
}