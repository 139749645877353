
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, delay, filter, tap } from 'rxjs';
import { MessageService } from 'src/services/message.service';
import { SHARED_DICTIONARY } from 'src/translations/dictionaries/shared.dictionary';
import { AUTHENTICATION_API_ACTIONS } from './store/authentication.actions';
import { AUTHENTICATION_SELECTORS } from './store/authentication.selectors';



@Component({
    selector: 'authentication-component',
    templateUrl: `./authentication.component.html`,
    styleUrls: ['./authentication.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AuthenticationComponent implements AfterViewInit, OnInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);
    public isLoading$: Observable<boolean>;


    public readonly sharedDictionary = SHARED_DICTIONARY;
    constructor(
        private _store: Store,
        private _elementRef: ElementRef,
        private _messageService: MessageService,
        private _actions: Actions,
        private _translateService: TranslateService,
    ) {
        this.isLoading$ = this._store.select(AUTHENTICATION_SELECTORS.selectIsLoading).pipe(
            delay(300)
        );
    }

    ngOnInit(): void {

        this._actions.pipe(
            ofType(AUTHENTICATION_API_ACTIONS.authenticateFailure),
            filter(value => Boolean(value.error)),
            tap(value => {
                this._messageService.addMessage({ messageText: this._translateService.instant(this.sharedDictionary.popupMessages.error.generic, { error: value.error }), alert: 'danger' });
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();
    }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('login-container');

    }

}