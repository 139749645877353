
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { NOT_FOUND_DICTIONARY } from 'src/translations/dictionaries/not-found.dictionary';


@Component({
    selector: 'not-found-component',
    templateUrl: 'not-found.component.html',
    styleUrls: ['not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class NotFoundComponent implements AfterViewInit {
    public notFoundDictionary = NOT_FOUND_DICTIONARY;

    constructor(private _elementRef: ElementRef) { }

    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('not-found-component');
    }

}