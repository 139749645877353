export const SHARED_TRANSLATIONS_EN = {
    shared: {
        button: {
            add: 'Add',
            next: 'Back',
            prev: 'Continue',
            save: 'Save',
            apply: 'Apply',
            change: 'Change',
            download: 'Download',
            edit: 'Edit',
            go: 'Go',
            delete: 'Remove',
            send: `Send`,
        },
        wine: {
            color: {
                red: 'Red',
                white: 'White',
                rose: 'Rosé',
            },
            types: {
                sparkling: 'Sparkling',
                fortified: 'Fortified',
                sweet: 'Sweet',
            }
        },
        company: {
            type: {
                winery: 'Winery',
                professional: 'Professional',
            }
        },
        user: {
            gender: {
                male: 'Male',
                female: 'Female',
                other: 'Other',
            }
        },
        dashboardTabs: {
            home: 'Home',
            elabel: 'ELabel',
            wines: 'Wines',
            dispenser: 'Dispenser',

            shop: `Shop`,
            company: `Company`,
            profile: `Profile`,
        },
        popupMessages: {
            error: {
                generic: `ERROR: {{error}}`,
                emailNotFound: `ERROR: no user found with this email`,
                codeMismatch: `ERROR: the code entered is incorrect`,
                createVintage: `ERROR: something went wrong while creating the wine`,
                notCantinaCompany: `ERROR: you must operate on behalf of a winery to continue`,
                wineryWronglySelected: `ERROR: the winery is not correctly selected`,
            },
            success: {
                companyCreated: `The company has been created successfully`,
                requestSent: `The request has been sent successfully`,
                addressCreated: `The location has been created correctly`,
                dataUpdated: `The data has been updated correctly`,
                accessDisabled: `Access for user {{email}} has been disabled`,
                accessDenied: `The user {{email}} request has been rejected`,
                companyAccessDisabled: `Access for company {{name}} has been disabled`,
                companyAccessDenied: `The company {{name}} request has been rejected`,
                userInvited: `User {{email}} has been invited to access your company`,
                userAccessAccepted: `You have approved access for user {{email}}`,
                companyAccessAccepted: `You have granted access to company {{name}}`,
                vintageCreated: `The wine has been created correctly`,
                recycleModelAdded: `The recycling model has been added correctly`,
                passwordUpdated: `The password has been updated successfully`,
                requestDeleted: `The request has been deleted correctly`,
            },
            warning: {
                fileFormatNotValid: `WARNING: the file format is not valid, upload files of type {{files}}`,
                compileMandatoryFields: `WARNING: please fill in all mandatory fields to proceed`,
                passwordMismatch: `WARNING: the passwords entered do not match`,
                imageSize: `WARNING: the image size does not meet the required standard, but`,
                formNotValid: `WARNING: please fill in all mandatory fields correctly`,
                verifyCompanyToProceed: `WARNING: Upload the documents to verify in order to proceed`,
            }
        },
        mainSearchbarSuggestions: {
            companySection: `Company Section`,
            companyUserSection: `The users who have access to your company`,
            wineSection: `Wines Section`,
            elabelSection: `ELabels Section`,
            profileSection: `Profile Section`,
            profileCompanySection: `The companies you are connected to`,
        }
    }
}