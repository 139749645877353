import { FormArray, FormGroup } from "@angular/forms";
import { SHA1, enc } from 'crypto-js';

export function moveItemInFormArray(
    formArray: FormArray,
    fromIndex: number,
    toIndex: number
): void {
    const dir = toIndex > fromIndex ? 1 : -1;

    const item = formArray.at(fromIndex);
    for (let i = fromIndex; i * dir < toIndex * dir; i = i + dir) {
        const current = formArray.at(i + dir);
        formArray.setControl(i, current);
    }
    formArray.setControl(toIndex, item);
}

export async function delayFunc(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


//Calculate ETAG

/*
function customEtag(entity:string, options: object) {
  
    const weak = options['weak'] || false;
    const tag = generateEntityTag(entity);
  
    return weak ? 'W/' + tag : tag;
  }
  
  function generateEntityTag(entity:string) {
    const len = Buffer.byteLength(entity, 'utf8');
    const hash = crypto.createHash('sha1').update(entity, 'utf8').digest('base64').substring(0, 27);
    return `"${len.toString(16)}-${hash}"`;
  }
  */

export function customEtag(entity: string, options: { weak?: boolean } = {}): string {
    if (typeof entity !== 'string') {
        throw new TypeError('argument entity must be a string');
    }

    const weak = options.weak || false;
    const tag = generateEntityTag(entity);

    return weak ? 'W/' + tag : tag;
}

function generateEntityTag(entity: string): string {
    const len = new TextEncoder().encode(entity).length;
    const hash = SHA1(entity).toString(enc.Base64).substring(0, 27);
    return `"${len.toString(16)}-${hash}"`;
}

export function calculateCanChangeUserAccessFromUrl(url: string): boolean {
    //implement all cases where the user should be able to switch accesses
    return !(
        url.includes('create') ||
        url.includes('edit') ||
        url.includes('/dashboard/company/locations/') ||
        url === '/dashboard/company/properties'
    );
}

export function getFormValidationErrors(formGroup: FormGroup) {
    const errors: { control: string, errorKey: string, errorMessage: string }[] = [];
    Object.keys(formGroup.controls).forEach(formKey => {
        const controlErrors = formGroup.get(formKey).errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(errorKey => {
                errors.push({
                    control: formKey,
                    errorKey: errorKey,
                    errorMessage: CONTROL_ERROR_KEY_MAP[errorKey] || `is ${errorKey}`
                })
            })
        }
    })
    return errors;
}

const CONTROL_ERROR_KEY_MAP: { [key: string]: string } = {
    required: 'é obbligatorio'
}


/**
 * function that resolve an object containing various translations trying to get the locale received in input
 * or resolves to the default fallback language
 * @param translatedField the field that have varioustranslations (ex:{'it':'ciao','en':'hello',...})
 * @param locale define the desired response language 
 * @param defaultFallback language to fallback if the desired one is not included in the translated field, default to en
 */
export function resolveDBLocaleFields(translatedField: { [key: string]: string }, locale: string, defaultFallback: string = 'en') {
    return translatedField[locale] || translatedField[defaultFallback];
}

export function generatePassword(length: number): string {
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()_+[]{}|;:,.<>?";

    function getRandomChar(characters: string): string {
        return characters[Math.floor(Math.random() * characters.length)];
    }

    // Ensure at least one of each required character type
    const passwordArray = [
        getRandomChar(uppercaseChars),
        getRandomChar(lowercaseChars),
        getRandomChar(numbers),
        getRandomChar(specialChars)
    ];

    // Fill the rest of the password length with random characters
    const allChars = uppercaseChars + lowercaseChars + numbers + specialChars;
    for (let i = passwordArray.length; i < length; i++) {
        passwordArray.push(getRandomChar(allChars));
    }

    // Shuffle the password array to prevent predictable sequences
    for (let i = passwordArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [passwordArray[i], passwordArray[j]] = [passwordArray[j], passwordArray[i]];
    }

    // Join the array into a string and return
    return passwordArray.join('');
}