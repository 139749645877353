import IUser, { UserAccessStatusEnum, UserSession } from '-albicchiere-types/lib/base/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { BackendService } from 'src/services/backend.service';
import { calculateCanChangeUserAccessFromUrl } from 'src/utils/utilities';
import { USER_ACCESS_API_ACTIONS, USER_ACCESS_PAGE_ACTIONS } from './userAccessStore.actions';
import { resolvedEnterpriseCompanyAccess } from './userAccessStore.state';


type userSessionResponseType = {
    user_session:
    (Omit<IUser, 'companyAccesses' | 'permissionOnEnterpriseCompanyAccess'> &
    { companyAccesses: UserSession['companyAccesses'] }
        &
    {
        permissionOnEnterpriseCompanyAccess?: {
            companyId: string;
            companyAccesses: resolvedEnterpriseCompanyAccess[];
        }[]
    })
}

@Injectable()
export class UserAccesEffects {

    constructor(private actions$: Actions, private _authService: AuthService,
        private _router: Router, private _backendService: BackendService) { }


    initUserAccesState = createEffect(() =>
        this.actions$.pipe(
            ofType(USER_ACCESS_PAGE_ACTIONS.initUserAcces),
            switchMap((_) => this._backendService.get<userSessionResponseType>('users/me/session', { useCompanyHeader: false }).pipe(
                map(response => {
                    const companyAccesses = response.user_session.companyAccesses
                        .filter(elm => (
                            elm.status === UserAccessStatusEnum.ACTIVE ||
                            elm.status === UserAccessStatusEnum.PENDING ||
                            elm.status === UserAccessStatusEnum.PENDING_DOCUMENT ||
                            elm.status === UserAccessStatusEnum.PENDING_EMAIL
                        ))
                    const enterpriceCompanyAccesses = response.user_session.permissionOnEnterpriseCompanyAccess;
                    const savedPreferredUserAccessId = localStorage.getItem('preferredUserAccessId');
                    return { companyAccesses, enterpriceCompanyAccesses, savedPreferredUserAccessId }
                }),
                map(({ companyAccesses, enterpriceCompanyAccesses, savedPreferredUserAccessId }) => {
                    const selectedCompanyAccess = companyAccesses.find(elm => elm._id === savedPreferredUserAccessId);
                    const selectedEnterpriseAccess: resolvedEnterpriseCompanyAccess = undefined;
                    const canChangeUserAccess = calculateCanChangeUserAccessFromUrl(this._router.routerState.snapshot.url);
                    return USER_ACCESS_API_ACTIONS.initUserAccesSuccess({
                        userAccesses: companyAccesses,
                        selectedUserAccess: selectedCompanyAccess || companyAccesses[0],
                        enterpriseAccesses: enterpriceCompanyAccesses,
                        selectedEnterpriseAccess: selectedEnterpriseAccess,
                        canChangeUserAccess
                    })
                }),
            )),
            catchError(error => of(USER_ACCESS_API_ACTIONS.initUserAccesFailure({ error })))
        )
    );


    updateUserAccess = createEffect(() =>
        this.actions$.pipe(
            ofType(USER_ACCESS_PAGE_ACTIONS.updateUserAccesses),
            switchMap(({ selectedAccessId, selectedCompanyId, selectedEnterpriseCompanyAccess, type }) => this._backendService.get<userSessionResponseType>('users/me/session', { useCompanyHeader: false }).pipe(
                map(response => {
                    const companyAccesses = response.user_session.companyAccesses
                        .filter(elm => (
                            elm.status === UserAccessStatusEnum.ACTIVE ||
                            elm.status === UserAccessStatusEnum.PENDING ||
                            elm.status === UserAccessStatusEnum.PENDING_DOCUMENT ||
                            elm.status === UserAccessStatusEnum.PENDING_EMAIL
                        ))
                    const enterpriceCompanyAccesses = response.user_session.permissionOnEnterpriseCompanyAccess;
                    const savedPreferredUserAccessId = localStorage.getItem('preferredUserAccessId');
                    return { companyAccesses, enterpriceCompanyAccesses, savedPreferredUserAccessId }
                }),
                map(({ companyAccesses, enterpriceCompanyAccesses, savedPreferredUserAccessId }) => {
                    const selectedCompanyAccess = selectedAccessId ? companyAccesses.find(companyAccess => companyAccess._id === selectedAccessId) :
                        selectedCompanyId ? companyAccesses.find(companyAccess => companyAccess.company._id === selectedCompanyId) :
                          /*  savedPreferredUserAccessId ? companyAccesses.find(elm => elm._id === savedPreferredUserAccessId) :*/ companyAccesses[0];
                    const selectedEnterpriseAccess: resolvedEnterpriseCompanyAccess = undefined
                    /* enterpriceCompanyAccesses?.find(elm => elm.companyId === selectedCompanyAccess.company._id)?.companyAccesses?.find(elm => elm._id === selectedEnterpriseCompanyAccess)
                     || undefined;*/
                    return USER_ACCESS_API_ACTIONS.updateUserAccessessSuccess({
                        userAccesses: companyAccesses,
                        selectedUserAccess: selectedCompanyAccess,
                        enterpriseAccesses: enterpriceCompanyAccesses,
                        selectedEnterpriseAccess: selectedEnterpriseAccess
                    })
                }),
            )),
            catchError(error => of(USER_ACCESS_API_ACTIONS.updateUserAccessessFailure({ error })))
        )
    );

    changeSelectedUserAcces$ = createEffect(() =>
        this.actions$.pipe(
            ofType(USER_ACCESS_PAGE_ACTIONS.changeSelectedUserAcces),
            tap(({ companyAccess, type }) => {
                if (companyAccess) {
                    localStorage.setItem('preferredUserAccessId', companyAccess._id);
                } else {
                    localStorage.setItem('preferredUserAccessId', undefined);
                }
            })

        ), {
        dispatch: false
    }
    );

}