import { createReducer, on } from "@ngrx/store";
import { COMPANY_USER_API_ACTIONS, COMPANY_USER_PAGE_ACTIONS } from "./company-user.action";
import { initInitialCompanyUserState } from "./company-user.state";

export const companyUserReducer = createReducer(
    initInitialCompanyUserState(),
    on(COMPANY_USER_PAGE_ACTIONS.initCompanyUserState, state => ({ ...state })),
    on(COMPANY_USER_PAGE_ACTIONS.setCompanyUserLocation, (state, { location }) => ({ ...state, selectedLocation: location })),

    on(COMPANY_USER_API_ACTIONS.initCompanyUserStateSuccess, (state, { companyAdmins, pendingUsers, activeUsers, companyAccesses }) => ({
        ...state,
        isLoading: false,
        companyAdmins,
        pendingUsers,
        activeUsers,
        companyAccesses,
        error: undefined,
    })),
    on(COMPANY_USER_API_ACTIONS.initCompanyUserStateFailure, (state, { error }) => ({
        ...state,
        error,
        isLoading: false,
        companyAdmins: [],
        pendingUsers: [],
        activeUsers: [],
        companyAccesses: [],

    })),

    on(COMPANY_USER_API_ACTIONS.updateCompanyUserStateSuccess, (state, { companyAdmins, pendingUsers, activeUsers, companyAccesses }) => ({
        ...state,
        isLoading: false,
        companyAdmins,
        pendingUsers,
        activeUsers,
        companyAccesses,
    })),
    on(COMPANY_USER_API_ACTIONS.updateCompanyUserStateFailure, (state, { error }) => ({ ...state, error })),

);