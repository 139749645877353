
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from "@ngx-translate/core";
import { filter, map, take, tap } from 'rxjs';
import { AUTHENTICATION_PAGE_ACTIONS } from 'src/authentication/store/authentication.actions';
import { AUTHENTICATION_SELECTORS } from 'src/authentication/store/authentication.selectors';
import { AuthenticationState } from 'src/authentication/store/authentication.state';
import { AuthService } from 'src/services/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: `./home.component.html`,
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, AfterViewInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    constructor(private _store: Store<AuthenticationState>, private _authService: AuthService,
        private _router: Router, private _translate: TranslateService, private _elementRef: ElementRef) {
        this._translate.setDefaultLang('en');
        this._translate.use('en');
        this._router.events.pipe(
            filter(ev => ev instanceof NavigationStart),
            map(ev => ev as NavigationStart),
            tap((ev: NavigationStart) => {
                //split url and query parameters from the request url
                const url = ev.url.includes('?') ? ev.url.split('?')[0] : ev.url;
                const params = ev.url.includes('?') ?
                    ev.url.split('?')[1].split('&').map(param => {
                        return {
                            key: param.split('=')[0],
                            value: param.split('=')[1],
                        }
                    })
                    : undefined;
                //save the splitted values to the ayth service
                this._authService.setRequestedUrl({ url: url, params: params });
            }),
            take(1),
        ).subscribe();
        this._store.dispatch(AUTHENTICATION_PAGE_ACTIONS.initAuthenticationStateFromStorage());
    }
    ngOnInit(): void {
        this._store.select(AUTHENTICATION_SELECTORS.selectLoggedUser).pipe(
            //filter(user => Boolean(user?.locale)),
            tap(user => {
                if (user?.locale) {
                    //Set language to user locale
                    this._translate.use(user.locale);
                } else {
                    //Set Language to browserLocale
                    const browserLang = navigator.language.split('-')[0];
                    this._translate.use(browserLang);
                }
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('light-mode');
    }


}