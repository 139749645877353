import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'findInArrayPipe'
})
export class FindInArrayPipe implements PipeTransform {

    constructor() { }

    transform(value: any[], ...args: [{ matchKey: string, matchValue: any }]) {
        const item = value.find(elm => elm[args[0].matchKey] === args[0].matchValue)
        return item;
    }
}