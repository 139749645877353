import { Pipe, PipeTransform } from "@angular/core";


const DISPENSER_ALERT_LIST: { [key: string]: string } = {
    'W01': "Serving temperature out of range",
    'W02': "Battery level below 20%	level",
    'W03': "No bag inside",
    'W04': "No socket connection",
    'W05': "Vacuum error",
    'E01': "Ambient temperature out of range",
    'E02': "Cooling system error",
    'E03': "Serving error",
    'E04': "Sensor error",
    'E05': "Wine temperature out of range",
    'E06': "Heatsink temperature out of range",
    'E07': "No battery detected",
    'L01': "Pouring",
    'L02': "Wine ready",
    'L03': "Factory reset",
    'L04': "Parental control (device)",
    'L05': "Parental control (smartBag)",
}

@Pipe({
    name: 'dispenserAlertTranslatePipe'
})
export class DispenserAlertTranslatePipe implements PipeTransform {

    constructor(
    ) { }

    transform(value: string, ...args: []): string {
        return DISPENSER_ALERT_LIST[value];
    }
}

