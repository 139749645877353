import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'resolveLocaleFieldPipe'
})
export class ResolveLocaleFieldPipe implements PipeTransform {

    constructor() { }

    transform(value: { [key: string]: string }, ...args: [{ locale: string }]) {
        return value[args[0].locale] || value['en']
    }
}