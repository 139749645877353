export const PROFILE_TRANSLATIONS_EN = {
    profile: {
        topDivider: {
            title: `Profile`,
            handleEmailButton: `Manage email`,
            handlePasswordButton: `Manage password`,
            logoutButton: `Logout`,
            changeEmailPanel: {
                title: `Change Email`,
                currEmailInput: `Current email`,
                newEmailInput: `New email`,
            },
            changePasswordPanel: {
                title: `Change Password`,
                description: `The password must be at least 8 characters long, and must also contain at least one number and
            a special character`,
                currPasswordInput: `Current password`,
                newPasswordInput: `New password`,
                confirmPasswordInput: `Confirm new password`,
            }
        },
        info: {
            title: `Personal Data`,
            nameInput: {
                title: `Name`,
                description: `Enter your name`,
            },
            lastnameInput: {
                title: `Last Name`,
                description: `Enter your last name`,
            },
            birthdateInput: {
                title: `Birthdate`,
                description: `Enter your birthdate`,
            },
            nationalityInput: {
                title: `Nationality`,
                description: `Select your nationality`,
            },
            genderInput: {
                title: `Gender`,
                description: `Select your gender`,
            },
        },
        settings: {
            title: `Settings`,
            languageInput: {
                title: `Language`,
                description: `Select the language`,
            },
            measureSystem: {
                title: `Measure System`,
                description: `Select the measure system`,
                options: {
                    metricSystem: `Metric System`,
                    imperialSystem: `Imperial System`,
                }
            },
            dateFormat: {
                title: `Date format`,
                description: `Select the date format`,
            },
            hourFormat: {
                title: `Time format`,
                description: `Select the time format`,
            }
        },
        privacy: {
            title: `Privacy`,
            deleteAccountButton: `Delete account`,
            consentList: {
                title: `List of consents provided`,
                termsAndConditions: `I declare that I have read and accepted the terms and conditions of the service.`,
                dataProcessing: `I authorize the processing of data to receive promotions and marketing activities
                                (optional).`,
                accountNotificationTitle: `Notifications on your account`,
                accountNotificationDescription: `Receive email notifications regarding your account (alerts, deadlines,
                                promotions, reminders)`,
            },
            rigthsSection: {
                title: `Your rights`,
                description: `To request access to your data and to exercise your rights related to your digital identity,
                    you can send us a request to "info@albicchiere.com" with the subject 'privacy'.
                    We will respond
                    within a
                    valid time, respecting your rights.`,
            },
            dangerSection: {
                title: `Danger zone`,
                description: `You can delete your account, which means losing all data and any saved progress.
                    The
                    procedure is not retroactive and you will not be able to manage the device if you own one (an account is required
                    for
                    configuration and management).`,
            },
            policyConsentPanel: {
                title: `Policy consent`,
                description: `Consent to the <a href="https://albicchiere.com/terms-of-service/" target="_blank">terms of service</a> and
            to the <a href="https://albicchiere.com/privacy-policy/" target="_blank">privacy policy</a>
            is required to use the albicchiere system. If you wish to revoke your
            consent, then proceed to request the deletion of your account.`,
            },
        },
        tabs: {
            home: `Home`,
            personalData: `Personal data`,
            company: `Company`,
            settings: `Settings`,
            privacy: `Privacy`,
        }
    }
}