import { createReducer, on } from "@ngrx/store";
import { USER_ACCESS_API_ACTIONS, USER_ACCESS_PAGE_ACTIONS } from "./userAccessStore.actions";
import { initInitialUserAccessState } from "./userAccessStore.state";

export const userAccessReducer = createReducer(
    initInitialUserAccessState(),
    on(USER_ACCESS_PAGE_ACTIONS.initUserAcces, state => ({ ...state, isLoading: true })),
    on(USER_ACCESS_PAGE_ACTIONS.setCanChangeUserAcces, (state, { value }) => ({ ...state, canChangeUserAccess: value })),
    on(USER_ACCESS_PAGE_ACTIONS.changeShowEnterprisePanelAccess, (state, { showEnterpriseAccessPanel }) => ({ ...state, showEnterpriseAccessPanel })),
    on(USER_ACCESS_PAGE_ACTIONS.changeSelectedUserAcces, (state, { companyAccess }) => ({
        ...state,
        selectedUserAccess: companyAccess,
        selectedEnterpriseAccess: undefined,
    })),
    on(USER_ACCESS_PAGE_ACTIONS.changeSelectedEnterpriseAccess, (state, { enterpriceAccess }) => ({ ...state, selectedEnterpriseAccess: enterpriceAccess })),

    on(USER_ACCESS_API_ACTIONS.initUserAccesSuccess, (state, { userAccesses, selectedUserAccess, enterpriseAccesses, selectedEnterpriseAccess, canChangeUserAccess }) => ({
        ...state,
        isLoading: false,
        userAccesses,
        selectedUserAccess,
        enterpriseAccesses,
        selectedEnterpriseAccess,
        canChangeUserAccess,
        initStoreDone: true,
    })),
    on(USER_ACCESS_API_ACTIONS.initUserAccesFailure, (state, { error }) => ({ ...state, error, isLoading: false })),


    on(USER_ACCESS_API_ACTIONS.updateUserAccessessSuccess, (state, { userAccesses, selectedUserAccess, enterpriseAccesses, selectedEnterpriseAccess }) => ({
        ...state,
        isLoading: false,
        userAccesses,
        selectedUserAccess,
        enterpriseAccesses,
        selectedEnterpriseAccess,
    })),
    on(USER_ACCESS_API_ACTIONS.updateUserAccessessFailure, (state, { error }) => ({ ...state, error, isLoading: false })),

);