<ng-container *ngIf="{
    selectedEnterpriseCompanyAccesses: selectedEnterpriseCompanyAccesses$ | async,
    enterpriseCompanyAccesses:enterpriseCompanyAccesses$ | async,
    enterpriseAccessSectionVisible:enterpriseAccessSectionVisible$ | async,
    canChangeCompany:canChangeCompany$ | async,
} as vm">
    <div *ngIf="vm.enterpriseCompanyAccesses?.length>0" class="enterprise-access-container">
        <div class="d-flex align-center j-between">
            <div>Stai lavorando per: {{vm.selectedEnterpriseCompanyAccesses?.company?.name || 'la tua company'}}
                {{vm.selectedEnterpriseCompanyAccesses?.location?' - '+
                vm.selectedEnterpriseCompanyAccesses.location?.name:''}}
            </div>
            <albi-button [buttonVariant]="'textOnly'" label="change"
                (onClick)="changeEnterpriseAccessSectionVisible(!vm.enterpriseAccessSectionVisible)"></albi-button>

        </div>
        <div *ngIf="vm.enterpriseAccessSectionVisible">
            <ng-container *ngIf="vm.canChangeCompany;else unchangeableCompanyTemplate">
                <div class="d-flex align-center j-between">
                    <div>Company Selection</div>
                    <div class="company-selection-wrapper">
                        <div *ngFor="let enterpriceCompanyAccess of vm.enterpriseCompanyAccesses"
                            class="company-selection-card"
                            [ngClass]="{'selected-card':vm.selectedEnterpriseCompanyAccesses?._id===enterpriceCompanyAccess?._id}"
                            (click)="onEnterpriceCompanyClick(enterpriceCompanyAccess)">
                            <div class="status-dot">{{' '}}</div>
                            <span class="avatar">
                                <img [src]="enterpriceCompanyAccess?.company?.image"
                                    onerror="this.src='assets/company-avatar.png'">
                            </span>
                            <div>
                                <h3>{{enterpriceCompanyAccess.company.name}}</h3>
                                <p *ngIf="enterpriceCompanyAccess?.location">
                                    {{enterpriceCompanyAccess?.location?.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>


    <ng-template #unchangeableCompanyTemplate>
        <p>Non puoi cambiare Accesso durante un flusso di creazione,modifica o rimozione.</p>
        <p>Esci dal flusso o torna alla home per cambiare Accesso</p>
    </ng-template>

</ng-container>