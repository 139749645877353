import { AUTHENTICATION_TRANSLATIONS_IT } from "./authentication.translations";
import { COMPANY_CONNECT_TRANSLATIONS_IT } from "./company-connect.translations";
import { COMPONENTS_TRANSLATIONS_IT } from "./components.translations";
import { DASHBOARD_TRANSLATIONS_IT } from "./dashboard.translations";
import { NOT_FOUND_TRANSLATIONS_IT } from "./not-found.translations";
import { PROFILE_TRANSLATIONS_IT } from "./profile.translations";
import { SHARED_TRANSLATIONS_IT } from "./shared.translations";


export const IT_TRANSLATIONS = {
    ...NOT_FOUND_TRANSLATIONS_IT,
    ...COMPANY_CONNECT_TRANSLATIONS_IT,
    ...COMPONENTS_TRANSLATIONS_IT,
    ...SHARED_TRANSLATIONS_IT,
    ...DASHBOARD_TRANSLATIONS_IT,
    ...PROFILE_TRANSLATIONS_IT,
    ...AUTHENTICATION_TRANSLATIONS_IT,
}