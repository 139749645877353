import { ICompanyAccess } from "-albicchiere-types/lib/base/company";
import { IUserCompanyAccess, UserAccessStatusEnum } from "-albicchiere-types/lib/base/user";
import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AlbiDropdownSizeEnum, AlbiOption } from "albi-ui/dist/albi-ui-library";
import { cloneDeep } from "lodash";
import { BehaviorSubject, catchError, concatMap, from, of, tap, toArray } from "rxjs";
import { COMPANY_USER_PAGE_ACTIONS } from "src/dashboards/company/users/store/company-user.action";
import { AlbiUser } from "src/dashboards/company/users/store/company-user.state";
import { BackendService } from "src/services/backend.service";
import { HeaderMessageService } from "src/services/header-message.service";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";
import { customEtag } from "src/utils/utilities";

type UserAccessesFormArray = FormArray<FormGroup<{
    accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
    location: FormControl<AlbiOption>;
    role: FormControl<AlbiOption>;
}>>;

@Component({
    selector: 'user-role-form',
    templateUrl: 'user-role-form.component.html',
    styleUrls: ['user-role-form.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserRoleFormComponent implements OnInit, AfterViewInit, OnChanges {
    private readonly _destroy: DestroyRef = inject(DestroyRef);

    @Input({ required: true }) user: AlbiUser & { accesses?: (IUserCompanyAccess & { roleName?: string, addressName?: string })[] };
    @Input({ required: true }) formStatus: 'assignation' | 'edit';
    @Input({ required: true }) companyLocations: AlbiOption[];
    @Input({ required: true }) roles: AlbiOption[];
    @Input({ required: true }) companyId: string;

    @Output() saveDone = new EventEmitter();

    //@Input() existingUserAccesses: IUserCompanyAccess[];


    public roleScopeValues: {
        key: string;
        name?: string;
        description?: string;
    }[];

    AlbiDropdownSizeEnum = AlbiDropdownSizeEnum;

    roleScopeControl: FormControl<'company' | 'location'>;

    accessesFormArray: UserAccessesFormArray;

    companyRoleDescription: string;
    locationRoleDescription: string;

    locationOptions$ = new BehaviorSubject<AlbiOption[]>([]);

    public readonly userRoleformDictionary = COMPONENTS_DICTIONARY.userRoleForm;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _elementRef: ElementRef,
        private _backendService: BackendService,
        private _headerMessageService: HeaderMessageService,
        private _store: Store,
        private _translateService: TranslateService,
    ) {
        this.roleScopeControl = new FormControl<'company' | 'location'>('location');

        this.locationRoleDescription = `Seleziona la location e il relativo ruolo che intendi assegnare all’utente. Fai salva per continuare.`;
        this.companyRoleDescription = `Seleziona il ruolo e fai salva per continuare.`;
    }
    ngOnChanges(changes: SimpleChanges): void {
        //console.log(changes['user'].currentValue)     
        if (changes?.['user']?.currentValue.accesses?.[0].location === undefined) {
            this.roleScopeControl.setValue('company');
        } else {
            this.roleScopeControl.setValue('location');
        }
    }
    ngOnInit(): void {

        this.roleScopeValues = [{
            key: 'location',
            name: this._translateService.instant(this.userRoleformDictionary.access.locationOption),
        }, {
            key: 'company',
            name: this._translateService.instant(this.userRoleformDictionary.access.companyOption),
        }];
        this.initAccessFormArray();
        if (this.user.accesses?.[0].location === undefined) {
            this.roleScopeControl.setValue('company');
        }
        this.calculateAccessFormArray(this.roleScopeControl.value);
        this.roleScopeControl.valueChanges.pipe(
            tap(value => {
                this.calculateAccessFormArray(value);
                //TODO: fix this -> in some case ended up to be null
                if (!value) {
                    this.roleScopeControl.setValue('location');
                }
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

        this.accessesFormArray.valueChanges.pipe(
            tap(accesses => {
                this.locationOptions$.next(
                    this.companyLocations.filter(loc => !accesses.map(acc => acc.location?.key).includes(loc.key))
                )
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();

    }
    ngAfterViewInit(): void {
        this._elementRef.nativeElement.classList.add('user-role-form')
    }

    removeLocationToRoleForm(index: number) {
        this.accessesFormArray.removeAt(index);
    }
    addLocationToRoleForm() {
        this.accessesFormArray.push(this.createRoleAndLocationGroup());
    }

    saveNewUserAccesses() {

        Object.values(this.accessesFormArray.controls).forEach(group => {
            Object.values(group.controls).forEach(control => {
                control.markAsTouched();
                control.updateValueAndValidity();
            });
        });
        this.accessesFormArray.updateValueAndValidity();
        if (!this.accessesFormArray.valid) {
            this._headerMessageService.addMessage({ alert: 'warning', messageText: this._translateService.instant(this.sharedDictionary.popupMessages.warning.compileMandatoryFields) });
            return
        }

        //calculate the accesses that needs to be deleted
        //checking the actual user accesses and looking which one is not in the new accesses array
        const accessesToBeDeleted = this.user.accesses?.filter(elm =>
            //get the new accesses from the raw value of the form array
            !this.accessesFormArray.getRawValue()
                //filter the ones with the access object (that means that those accesses are old existing access to be edited or ignored)
                .filter(acc => Boolean(acc.accessObject))
                //map to get all the ids
                .map(acc => acc.accessObject._id)
                //check if the user existing accesses are included in this list
                //with the negation added at the start this will get all the accesses
                //that are no longer wanted hence to be deleted
                .includes(elm._id)
        ) || [];

        //check between the new accesses the one that are create with an ID 
        const accessesToBeEdited = this.accessesFormArray.getRawValue()
            //cut out the one that don't have accessObject value
            .filter(acc => acc.accessObject)
            //then compare the existing access to the new one to check if the location or the role are changed 
            .filter(acc => {
                const existingAccess = this.user.accesses?.find(val => val._id === acc.accessObject._id);
                return existingAccess?.location !== acc.location.key || existingAccess.role !== acc.role.key;
            });

        //check between the new accesses the ones that don't have accessObject property so that means they are new ones
        const accessToBeCreated = this.accessesFormArray.getRawValue().filter(acc => !acc.accessObject);

        const createObservables = accessToBeCreated.map(access => this._backendService.post(`companies/${this.companyId}/user_accesses`, {
            userId: this.user._id,
            role: access.role?.key,
            location: access.location?.key,
            company: this.companyId,
            status: UserAccessStatusEnum.ACTIVE
        }));
        const editObservables = accessesToBeEdited.map(access => {
            const etag = customEtag(JSON.stringify(access.accessObject));
            return this._backendService.patch(`companies/${this.companyId}/user_accesses/${access.accessObject._id}`, etag, {
                location: access.location?.key,
                role: access.role?.key,
                status: UserAccessStatusEnum.ACTIVE,
            })
        });
        const deleteObservables = accessesToBeDeleted.map(access => {
            const userAccessObject = cloneDeep(access);
            delete userAccessObject.addressName;
            delete userAccessObject.roleName;
            const etag = customEtag(JSON.stringify(userAccessObject));
            return this._backendService.delete(`companies/${this.companyId}/user_accesses/${access._id}`, etag)
        });

        //from(): converts the array of observables into an observable sequence.
        //concatMap(): ensures that each observable is processed sequentially.
        //toArray(): Used toArray() to collect the results after all observables have completed.
        /*Using concatMap along with toArray will wait for all observables to complete
         and then emit the final values as an array, similar to forkJoin. However, the key difference 
         is that concatMap processes each observable sequentially, one at a time, instead of in parallel.*/
        //ERROR: with forkJoin the user somethimes get deleted from the accesses, probably the server doesnt handle the parallel calls very well
        from([
            ...createObservables,
            ...editObservables,
            ...deleteObservables
        ]).pipe(
            concatMap(observable => observable),  // Use concatMap to ensure one call at a time
            toArray(),
            tap(response => {
                console.log(response);
                this._headerMessageService.addMessage({ alert: "success", messageText: this._translateService.instant(this.sharedDictionary.popupMessages.success.dataUpdated) });
                // this.reloadUsers$.next();
                this._store.dispatch(COMPANY_USER_PAGE_ACTIONS.updateCompanyUserState());
                // this.roleEditPanelVisible$.next(false);
                this.saveDone.emit();
            }),
            catchError(err => {
                console.error(err);
                this.saveDone.emit();
                return of(this._headerMessageService.addMessage({ alert: 'danger', messageText: this._translateService.instant(this.sharedDictionary.popupMessages.error.generic, { error: err }) }));
            })
        ).subscribe();

    }

    private createRoleAndLocationGroup(location?: AlbiOption, role?: AlbiOption, accessObject?: IUserCompanyAccess | ICompanyAccess): FormGroup<{
        accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
        location: FormControl<AlbiOption>;
        role: FormControl<AlbiOption>;
    }> {
        if (accessObject) {
            return new FormGroup<{
                accessObject?: FormControl<IUserCompanyAccess | ICompanyAccess>,
                location: FormControl<AlbiOption>;
                role: FormControl<AlbiOption>;
            }>({
                accessObject: new FormControl<IUserCompanyAccess | ICompanyAccess>(accessObject),
                location: new FormControl(location || null, Validators.required),
                role: new FormControl(role || null, Validators.required)
            })
        }
        return new FormGroup({
            location: new FormControl(location || null, Validators.required),
            role: new FormControl(role || null, Validators.required)
        })
    }

    private clearAccessFormArray() {
        if (!this.accessesFormArray) {
            this.initAccessFormArray();
        }
        while (this.accessesFormArray.length !== 0) {
            this.accessesFormArray.removeAt(0);
        }
    }

    private initAccessFormArray() {
        this.accessesFormArray = new FormArray([]);
    }

    private calculateAccessFormArray(roleScope: 'location' | 'company') {
        if (this.formStatus === 'assignation') {
            const existingUserAccesses = this.user.accesses[0];
            this.clearAccessFormArray();
            this.accessesFormArray.push(this.createRoleAndLocationGroup(
                roleScope === 'company' ? { key: undefined, label: 'Company' } : undefined,
                undefined,
                this.user.accesses[0]
            ))

        } else {
            const existingUserAccesses = this.user.accesses?.filter(elm => roleScope === 'location' ? Boolean(elm.location) : Boolean(!elm.location)) || [];
            //if (this.user.accesses[0].status === UserAccessStatusEnum.PENDING) {
            //     existingUserAccesses.push(this.user.accesses[0]);
            // }
            this.clearAccessFormArray();
            if (existingUserAccesses.length > 0) {
                existingUserAccesses.forEach(access => {
                    this.accessesFormArray.push(this.createRoleAndLocationGroup(
                        this.companyLocations.find(loc => loc.key === access.location) || { key: undefined, label: 'Company' },
                        this.roles.find(role => role.key === access.role),
                        access
                    ))
                })
            } else {
                this.accessesFormArray.push(this.createRoleAndLocationGroup(
                    roleScope === 'company' ? { key: undefined, label: 'Company' } : undefined
                ))
            }
        }
        if (roleScope === 'company') {
            this.accessesFormArray.controls.at(0).controls.location.disable();
        }

    }

}