import { ICompany } from "-albicchiere-types/lib/base/company";
import IUser, { UserAccessStatusEnum, UserSession } from "-albicchiere-types/lib/base/user";
import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewEncapsulation } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { Router, Scroll } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, filter, map, Observable, tap } from "rxjs";
import { AUTHENTICATION_SELECTORS } from "src/authentication/store/authentication.selectors";
import { COMPONENTS_DICTIONARY } from "src/translations/dictionaries/components.dictionary";
import { SHARED_DICTIONARY } from "src/translations/dictionaries/shared.dictionary";
import { USER_ACCESS_PAGE_ACTIONS } from "src/userAccessStore/userAccessStore.actions";
import { USER_ACCES_SELECTORS } from "src/userAccessStore/userAccessStore.selectors";
import { MAIN_BAR_SUGGESTIONS, ResolvedUserAccessType } from "src/utils/sharedValues";
import { calculateCanChangeUserAccessFromUrl } from "src/utils/utilities";

@Component({
    selector: 'app-top-bar',
    templateUrl: 'app-top-bar.component.html',
    styleUrls: ['app-top-bar.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTopBarComponent implements OnInit {
    private readonly _destroy: DestroyRef = inject(DestroyRef);


    public user$ = new Observable<IUser>;

    public mainBarSuggestions$ = new BehaviorSubject<any[]>(null);

    searchBarControl = new FormControl<string | object>(undefined);

    UserAccessStatusEnum = UserAccessStatusEnum;

    public companyAccesses$ = new Observable<(ICompany & { accesses: UserSession['companyAccesses'] })[]>;
    public selectedCompanyAccess$ = new Observable<ResolvedUserAccessType>;
    public canChangeCompany$: Observable<boolean>;

    public showChangeCompanyPanel$ = new BehaviorSubject<boolean>(false);

    public readonly appTopbarDictionary = COMPONENTS_DICTIONARY.appTopbar;
    public readonly sharedDictionary = SHARED_DICTIONARY;

    constructor(
        private _router: Router,
        private _store: Store,
        private _translateService: TranslateService,
    ) { }


    ngOnInit(): void {
        this.user$ = this._store.select(AUTHENTICATION_SELECTORS.selectLoggedUser);//(this._backendService.get<{ user: IUser }>('users/me')).pipe(map(response => response.user));
        this.canChangeCompany$ = this._store.select(USER_ACCES_SELECTORS.selectCanChangeUserAcces);

        this.selectedCompanyAccess$ = this._store.select(USER_ACCES_SELECTORS.selectSelectedUserAccesses);
        this.companyAccesses$ = this._store.select(USER_ACCES_SELECTORS.selectUserAccesses).pipe(
            map(userAccesses =>
                userAccesses?.reduce((acc: (ICompany & { accesses: UserSession['companyAccesses'] })[], curr) => {
                    const companyIndex = acc.findIndex(elm => elm._id === curr.company._id);
                    const currStatus = curr.status === UserAccessStatusEnum.ACTIVE ? 'active' : 'pending';
                    if (companyIndex === -1) {
                        acc.push({
                            ...curr.company,
                            accesses: [curr],
                        });
                    }
                    else {
                        acc[companyIndex].accesses.push(curr);
                    }
                    return acc;
                }, [])
            )
        );

        this._router.events.pipe(
            filter(event => event instanceof Scroll),
            tap(value => {
                const canChangeUserAccess = calculateCanChangeUserAccessFromUrl((value as Scroll).routerEvent.url);
                this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.setCanChangeUserAcces({ value: canChangeUserAccess }));
            }),
            takeUntilDestroyed(this._destroy)
        ).subscribe();
    }



    redirectToUserPage() {
        // window.open(`profile/info`, "_blank");
        this._router.navigate(['profile/info']);
    }


    completeMainBarSearch(ev: string) {
        if (ev) {
            const searchbarSuggestions = MAIN_BAR_SUGGESTIONS.map(elm => ({
                ...elm,
                label: this._translateService.instant(this.sharedDictionary.mainSearchbarSuggestions[elm.name as keyof typeof this.sharedDictionary.mainSearchbarSuggestions])
            }))
            const suggestions = searchbarSuggestions.filter(elm => elm.tags.some(tag => tag.includes(ev.toLowerCase())));
            suggestions.length > 0 ? this.mainBarSuggestions$.next(suggestions) : this.mainBarSuggestions$.next(null);
        } else {
            this.mainBarSuggestions$.next(null);
        }
    }

    mainSuggestionClick(suggestion: { path: string, name: string, tags: string[] }) {
        this.searchBarControl.setValue(undefined);
        this._router.navigate([suggestion.path]);
    }


    changeSelectedCompany(companyGroupedAccesses: ICompany & { accesses: UserSession['companyAccesses'] }, access?: ResolvedUserAccessType) {
        //op.hide();
        this.changeShowChangeCompanyPanel(false);
        const selectedAccess = access || companyGroupedAccesses.accesses.find(elm => elm.location == undefined) || companyGroupedAccesses.accesses[0];
        this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedUserAcces({ companyAccess: selectedAccess }));
        switch (true) {
            case (selectedAccess.status === UserAccessStatusEnum.ACTIVE || Boolean(selectedAccess.role)):
                this._router.navigate(['dashboard']);
                break
            case (selectedAccess.status === UserAccessStatusEnum.PENDING):
            case (selectedAccess.status === UserAccessStatusEnum.PENDING_DOCUMENT):
            case (selectedAccess.status === UserAccessStatusEnum.PENDING_EMAIL):
                this._router.navigate(['company-pending-verification']);
                break
        }
    }

    navigateToOnboardingConnect() {
        // op.hide();
        this.changeShowChangeCompanyPanel(false);
        this._store.dispatch(USER_ACCESS_PAGE_ACTIONS.changeSelectedUserAcces({ companyAccess: null }));
        this._router.navigate(['company-connect']);
    }

    changeShowChangeCompanyPanel(ev: boolean) {
        this.showChangeCompanyPanel$.next(ev);
    }
}