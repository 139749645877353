import { ICompany, ICompanyAddress } from "-albicchiere-types/lib/base/company";
import { IRole } from "-albicchiere-types/lib/base/role";
import { UserAccessStatusEnum, UserSession } from "-albicchiere-types/lib/base/user";
import { ResolvedUserAccessType } from "src/utils/sharedValues";

export type resolvedEnterpriseCompanyAccess = {
    company: ICompany,
    location: ICompanyAddress,
    role: IRole,
    status: UserAccessStatusEnum,
    _id: string,
    createdAt: Date,
    updatedAt: Date,
}


export interface UserAccessState {
    error: string | null;
    isLoading: boolean;
    userAccesses: UserSession['companyAccesses'] | null;
    selectedUserAccess: ResolvedUserAccessType | null;
    canChangeUserAccess: boolean;
    enterpriseAccesses: { companyId: string; companyAccesses: resolvedEnterpriseCompanyAccess[]; }[] | null,
    selectedEnterpriseAccess: resolvedEnterpriseCompanyAccess | null,
    showEnterpriseAccessPanel: boolean,
    initStoreDone: boolean,
}

export const initInitialUserAccessState = (): UserAccessState => ({
    error: null,
    isLoading: false,
    userAccesses: null,
    selectedUserAccess: null,
    canChangeUserAccess: true,
    enterpriseAccesses: null,
    selectedEnterpriseAccess: null,
    showEnterpriseAccessPanel: false,
    initStoreDone: false,
});

export const USER_ACCESS_FEATURE = 'userAccessFeature';