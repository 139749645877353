import { ICompany, ICompanyAccess, ICompanyAddress } from "-albicchiere-types/lib/base/company";
import { IRole } from "-albicchiere-types/lib/base/role";
import IUser, { IUserCompanyAccess } from "-albicchiere-types/lib/base/user";
import { AlbiOption } from "albi-ui/dist/albi-ui-library";


export type AlbiUser = {
    _id: string,
    name: string,
    lastName: string,
    email: string,
    permissionOnEnterpriseCompanyAccess?: IUser['permissionOnEnterpriseCompanyAccess'],
    avatar?: string,
};
export type ResolvedCompanyAccesses = {
    company: ICompany;
    accesses: {
        accessObject?: ICompanyAccess;
        location?: ICompanyAddress;
        role: IRole;
    }[]
}

export type AlbiUserCompanyAccess = AlbiUser & {
    roleTableTag?: string,
    addressTableTag?: string,
    accesses: (IUserCompanyAccess & { roleName?: string, addressName?: string })[];
};

export interface CompanyUserState {
    error: string | null;
    companyAdmins: AlbiUserCompanyAccess[] | null;
    pendingUsers: AlbiUserCompanyAccess[] | null;
    activeUsers: AlbiUserCompanyAccess[] | null;
    selectedLocation: AlbiOption | null;
    companyAccesses: ResolvedCompanyAccesses[] | null;
}

export const initInitialCompanyUserState = (): CompanyUserState => ({
    error: null,
    companyAdmins: null,
    pendingUsers: null,
    activeUsers: null,
    selectedLocation: null,
    companyAccesses: null,
});

export const COMPANY_USER_FEATURE = 'companyUserFeature';