import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'resolveVintageName'
})
export class ResolveVintageName implements PipeTransform {

    constructor() { }

    transform(value: { fullName: string, year: number | 'N.V.', yearDetails?: string }, args?: { includeWinery: boolean }) {
        const wineryName = value?.fullName?.split('|')[0];
        const wineName = value?.fullName?.split('|')[1];
        const vintageDetail = value?.year === 'N.V.' ? (value?.yearDetails ? value?.yearDetails : 'N.V.') : value?.year;
        if (args?.includeWinery) {
            return `${wineryName}, ${wineName} ${vintageDetail} `
        }
        return `${wineName} ${vintageDetail}`
    }
}