import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'resolveDateStringPipe'
})
export class ResolveDateStringPipe implements PipeTransform {

    constructor() { }

    transform(value: string | Date, ...args: any[]) {
        const date = new Date(value);
        return date.toLocaleTimeString() + ' ' + date.toLocaleDateString();
    }
}