import { AUTHENTICATION_TRANSLATIONS_EN } from "./authentication.translations";
import { COMPANY_CONNECT_TRANSLATIONS_EN } from "./company-connect.translations";
import { COMPONENTS_TRANSLATIONS_EN } from "./components.translations";
import { DASHBOARD_TRANSLATIONS_EN } from "./dashboard.translations";
import { NOT_FOUND_TRANSLATIONS_EN } from "./not-found.translations";
import { PROFILE_TRANSLATIONS_EN } from "./profile.translations";
import { SHARED_TRANSLATIONS_EN } from "./shared.translations";


export const EN_TRANSLATIONS = {
    ...NOT_FOUND_TRANSLATIONS_EN,
    ...COMPANY_CONNECT_TRANSLATIONS_EN,
    ...COMPONENTS_TRANSLATIONS_EN,
    ...SHARED_TRANSLATIONS_EN,
    ...DASHBOARD_TRANSLATIONS_EN,
    ...PROFILE_TRANSLATIONS_EN,
    ...AUTHENTICATION_TRANSLATIONS_EN,
}