<ng-container *ngIf="{
    countrySuggestion:countrySuggestion$ | async
} as vm">
    <div class="form-group">
        <div class="form-input-label col-span-2" *ngIf="formTitle || formDescription">
            <div class="text-lg font-bold" *ngIf="formTitle">{{formTitle}}</div>
            <div class="text-sm font-regular" *ngIf="formDescription">{{formDescription}}</div>
        </div>
        <div class="form-input-wrapper col-span-2">
            <div class="form-input-label">
                <div class="text-sm font-bold">{{addressFormDictionary.nameInput.title | translate}}</div>
                <div class="text-sm font-regular">{{addressFormDictionary.nameInput.description | translate}}</div>
            </div>
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.name"
                autocomplete="address-level2"></albi-input>
            <label class="d-flex align-center cliccable font-medium text-xs">
                <albi-checkbox [control]="companyAddressForm.controls.isShipping"></albi-checkbox>
                {{addressFormDictionary.addressCheckbox.shippingText| translate}}
            </label>
            <label class="d-flex align-center cliccable font-medium text-xs">
                <albi-checkbox [control]="companyAddressForm.controls.isBilling"></albi-checkbox>
                {{addressFormDictionary.addressCheckbox.billingText| translate}}
            </label>
        </div>
        <div class="form-input-label col-span-2">
            <div class="text-sm font-bold">{{addressFormDictionary.addressInput.title | translate}}</div>
            <div class="text-sm font-regular">{{addressFormDictionary.addressInput.description | translate}}</div>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.address" autocomplete="street-address"
                [placeholder]="addressFormDictionary.addressInput.placeholder | translate"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.city" autocomplete="address-level2"
                [placeholder]="addressFormDictionary.cityInput.placeholder | translate"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [control]="companyAddressForm.controls.state" autocomplete="address-level1"
                [placeholder]="addressFormDictionary.stateInput.placeholder | translate"></albi-input>
        </div>
        <div class="form-input-wrapper">
            <albi-autocomplete dataKey="name" [placeholder]="addressFormDictionary.countryInput.placeholder | translate"
                [isFluidElement]="true" autocomplete="country" [suggestions]="vm.countrySuggestion"
                (completeMethod)="changeCountrySuggestion($event)" [control]="companyAddressForm.controls.country">
                <ng-template #itemTemplate let-suggestion>
                    {{suggestion?.name}}
                </ng-template>
            </albi-autocomplete>
        </div>
        <div class="form-input-wrapper">
            <albi-input [fullWidth]="true" [placeholder]="addressFormDictionary.postalCodeInput.placeholder | translate"
                [control]="companyAddressForm.controls.postalCode" autocomplete="postal-code"></albi-input>
            <!-- placeholder="Codice postale"></albi-input> -->
        </div>
    </div>
</ng-container>