import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { EN_TRANSLATIONS } from 'src/translations/en/index.translations';
import { IT_TRANSLATIONS } from 'src/translations/it/index.translations';

export class AlbiTranslateLoader implements TranslateLoader {
    getTranslation(lang: string): Observable<any> {
        switch (lang) {
            case 'it':
                return of(IT_TRANSLATIONS);
            case 'en':
            default:
                return of(EN_TRANSLATIONS);
        }
    }
}

